// loginSlice.js
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      console.log("login action payload:", action.payload)
      state.loading = false;
      state.error = action.payload.error;
      state.data = action.payload.data;
      
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loginRemove : (state, action) =>{
      state.data = ''
    }
  },
});

export const { loginRequest, loginSuccess, loginFailure, loginRemove } = loginSlice.actions;
export const selectLogin = (state) => state.login;
export default loginSlice.reducer;
