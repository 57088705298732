// servicesActions.js
import {
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    createCategoryStart,
    createCategorySuccess,
    createCategoryFailure,
    updateCategoryStart,
    updateCategorySuccess,
    updateCategoryFailure,
  } from "../slices/categorySlice";
  
  import {
    createCategoryAPI,
    updateCategoryAPI,
    fetchCategoriesAPI,
  } from "../../api/services.api";
  
  export const fetchCategories = () => async (dispatch) => {
    try {
      dispatch(fetchCategoriesStart());
      const services = await fetchCategoriesAPI(dispatch);
      dispatch(fetchCategoriesSuccess(services));
    } catch (error) {
      dispatch(fetchCategoriesFailure(error.message));
    }
  };
  export const fetchSubCatefories = () => async (dispatch) => {
    try {
      dispatch(fetchCategoriesStart());
      const services = await fetchCategoriesAPI(dispatch);
      dispatch(fetchCategoriesSuccess(services));
    } catch (error) {
      dispatch(fetchCategoriesFailure(error.message));
    }
  };
  
  export const createCategory = (serviceData) => async (dispatch) => {
    try {
      dispatch(createCategoryStart());
      await createCategoryAPI(serviceData, dispatch);
      dispatch(createCategorySuccess());
    } catch (error) {
      dispatch(createCategoryFailure(error.message));
    }
  };
  
  export const updateCategory = ( serviceData) => async (dispatch) => {
    try {
      dispatch(updateCategoryStart());
      await updateCategoryAPI(serviceData,dispatch);
      dispatch(updateCategorySuccess());
    } catch (error) {
      dispatch(updateCategoryFailure(error.message));
    }
  };
  