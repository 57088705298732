// servicesActions.js
import {
  fetchServicesStart,
  fetchServicesSuccess,
  fetchServicesFailure,
  createServiceStart,
  createServiceSuccess,
  createServiceFailure,
  updateServiceStart,
  updateServiceSuccess,
  updateServiceFailure,
  filterServices
} from "../slices/servicesSlice";

import {
  createServiceAPI,
  updateServiceAPI,
  fetchServicesAPI,
} from "../../api/services.api";

export const fetchServices = () => async (dispatch) => {
  try {
    dispatch(fetchServicesStart());
    const services = await fetchServicesAPI(dispatch);
    dispatch(fetchServicesSuccess(services));
  } catch (error) {
    dispatch(fetchServicesFailure(error.message));
  }
};
export const fetchSubCatefories = () => async (dispatch) => {
  try {
    dispatch(fetchServicesStart());
    const services = await fetchServicesAPI(dispatch);
    dispatch(fetchServicesSuccess(services));
  } catch (error) {
    dispatch(fetchServicesFailure(error.message));
  }
};

export const createService = (serviceData) => async (dispatch) => {
  try {
    dispatch(createServiceStart());
    await createServiceAPI(serviceData, dispatch);
    dispatch(createServiceSuccess());
  } catch (error) {
    dispatch(createServiceFailure(error.message));
  }
};

export const updateService = ( serviceData) => async (dispatch) => {
  try {
    dispatch(updateServiceStart());
    await updateServiceAPI(serviceData,dispatch);
    dispatch(updateServiceSuccess());
  } catch (error) {
    dispatch(updateServiceFailure(error.message));
  }
};

export const filterServicesAction = ( subCatId) => async (dispatch) => {
  dispatch(filterServices(subCatId))
};
