import React, { useState } from "react";
import { AppIcons } from "../assets";

export const ImageComponent = ({ src, alt, customClass }) => (
  <img loading="lazy" src={src} alt={alt} className={customClass} />
);

const MessageBlock = ({ message, timestamp, onClick, isMine }) => (
  <button
    onClick={onClick}
    className="flex gap-[21px] px-5 py-6  rounded-none"
  >
    {!isMine && <img src={AppIcons.UserImage} alt="UserImage" />}
    <div>
      <div
        className={`text-[16px] tracking-normal py-2 px-3 text-zinc-950 rounded-tl-xl rounded-bl-xl rounded-br-xl bg-[#F2F7FB] ${
          isMine && "bg bg-gradient-to-r from-[#076B9E] to-[#01A0AA] text-white"
        }`}
      >
        {message}
      </div>
      <div
        className={`text-sm leading-loose text-neutral-500 text-left ${
          isMine && "text-right"
        }`}
      >
        {timestamp}
      </div>
    </div>
  </button>
);

function ChatBox() {
  const [messages] = useState([
    { message: "Hope you like it", timestamp: "09:25 AM" },
    { message: "Hello! Jhon Abraham", timestamp: "09:25 AM", user: true },
    { message: "Hope you like it", timestamp: "09:25 AM" },
    { message: "Hello! Jhon Abraham", timestamp: "09:25 AM" },
  ]);

  const handleClick = () => {
    console.log("Message clicked");
  };

  return (
    <div className="flex flex-col grow py-7 w-full bg-white rounded-xl shadow-sm max-md:max-w-full h-[calc(100vh-128px)] no-scrollbar overflow-auto relative">
      <header className="flex flex-col px-7 max-md:px-5 max-md:max-w-full flex-1">
        <div className="flex justify-between">
          <div className="flex gap-[19px] mb-4 items-center">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&"
              alt="Profile"
              className="h-16 w-16 rounded-s-2xl"
            />
            <div>
              <h3 className="text-[16px] font-bold m-0">Ahsen Shamil</h3>
              <p className="font-medium m-0 mt-1">Manager</p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button className="rounded-[50%] h-[50px] w-[50px] flex items-center justify-center bg-[#E6F2F6]">
              <svg
                width="15"
                height="15"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1391 25.9425C18.4155 26.7672 19.9364 27.1266 21.4463 26.9603C22.9561 26.794 24.3627 26.112 25.4295 25.0292L26.3622 24.1159C26.7711 23.697 27 23.1344 27 22.5486C27 21.9628 26.7711 21.4003 26.3622 20.9814L22.4035 17.0582C21.9888 16.65 21.4306 16.4213 20.8491 16.4213C20.2676 16.4213 19.7094 16.65 19.2946 17.0582C18.8764 17.4677 18.3147 17.697 17.7298 17.697C17.1449 17.697 16.5833 17.4677 16.165 17.0582L9.94725 10.8309C9.73983 10.6262 9.57512 10.3822 9.46269 10.1131C9.35026 9.84408 9.29237 9.55533 9.29237 9.26367C9.29237 8.97201 9.35026 8.68326 9.46269 8.41421C9.57512 8.14516 9.73983 7.90118 9.94725 7.69646C10.3548 7.28109 10.5832 6.72201 10.5832 6.13962C10.5832 5.55724 10.3548 4.99816 9.94725 4.58279L6.00933 0.638814C5.59109 0.229295 5.02942 0 4.44452 0C3.85962 0 3.29795 0.229295 2.87971 0.638814L1.96778 1.57291C0.8866 2.6414 0.205731 4.05015 0.0396515 5.56228C-0.126428 7.07441 0.232413 8.59771 1.05584 9.87602C5.34619 16.2087 10.8052 21.6619 17.1391 25.9425Z"
                  fill="url(#paint0_linear_0_527)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_0_527"
                    x1="24.5551"
                    y1="27"
                    x2="0.323717"
                    y2="24.3546"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#F0BA47" />
                    <stop offset="0.677095" stop-color="#F3D25C" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
            <svg
              width="50"
              height="35"
              viewBox="0 0 75 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="75"
                width="40"
                height="75"
                rx="20"
                transform="rotate(90 75 0)"
                fill="#0679A2"
                fill-opacity="0.1"
              />
              <circle
                cx="26.4091"
                cy="20"
                r="3.40909"
                fill="url(#paint0_linear_0_897)"
              />
              <circle
                cx="37.9999"
                cy="20"
                r="3.40909"
                fill="url(#paint1_linear_0_897)"
              />
              <circle
                cx="49.5907"
                cy="20"
                r="3.40909"
                fill="url(#paint2_linear_0_897)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_0_897"
                  x1="23.1147"
                  y1="16.5909"
                  x2="29.9643"
                  y2="16.741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#01A0AA" />
                  <stop offset="1" stop-color="#076B9E" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_0_897"
                  x1="34.7055"
                  y1="16.5909"
                  x2="41.5551"
                  y2="16.741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#01A0AA" />
                  <stop offset="1" stop-color="#076B9E" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_0_897"
                  x1="46.2963"
                  y1="16.5909"
                  x2="53.146"
                  y2="16.741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#01A0AA" />
                  <stop offset="1" stop-color="#076B9E" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="mt-2 h-[calc(100%-170px)] border-t border-solid border-neutral-200 max-md:max-w-full overflow-y-auto">
          {messages.map((msg, index) => (
            <div className={`flex ${msg.user && "justify-end"}`}>
              <MessageBlock
                key={index}
                message={msg.message}
                timestamp={msg.timestamp}
                isMine={msg.user}
                onClick={handleClick}
              />
            </div>
          ))}
        </div>
      </header>
      <footer className="flex absolute bottom-5 w-[calc(100%-16px)] items-center justify-between px-px mt-1.5 mr-10 ml-7 text-lg leading-3 whitespace-nowrap text-neutral-500 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full bg-white">
        <img 
        src={AppIcons.Attachment}
        alt="Attachment"
        className="mr-5"
        />
        <input
          className="grow px-8 py-7 bg-gray-100 rounded-xl max-md:px-5 max-md:max-w-full"
          placeholder="Write your message"
        />
        <button
          className="mx-3 bg-transparent"
          onClick={() => console.log("Send message")}
        >
          <img src={AppIcons.Send} alt="Send" />
        </button>
      </footer>
    </div>
  );
}

export default ChatBox;
