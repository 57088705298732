import axios from "axios";
import FormData from 'form-data'
import { getAccessTokenFromCookie } from "../utilities/cookieUtils";
import {createImageObjectURL} from "../utilities"
import AppConfig from "../config"

// Get image upload URL
export const fetchImage = async (imagePath) => {
  try {
    const accessToken = getAccessTokenFromCookie();
    const response = await fetch(
      `${AppConfig.API_BASE_URL}image-uploads/${imagePath}`,
      {
        headers: {
          "Content-Type": "image/png",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return createImageObjectURL(response); // Assuming the response contains the image data
  } catch (error) {
    throw new Error(
      error.response.data.message || "Failed to get image upload URL"
    );
  }
};

// Upload image
export const uploadImage = async (folder, subFolder, image) => {
  try {
    const accessToken = getAccessTokenFromCookie();
    const formData = new FormData();
    formData.append('file', image); // Append the image file to the form data

    const response = await axios.post(
      `${AppConfig.API_BASE_URL}image-uploads/${folder}/${subFolder}`,
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: `Bearer ${accessToken}`,
        },
        // This configuration ensures that Axios doesn't transform the request data
        // transformRequest: [(data, headers) => data],
      }
    );
    return response.data; // Assuming the response contains the uploaded image URL
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to upload image");
  }
};

