import React, { useState } from "react";

const StarRating = ({ value }) => {
  //   const handleClick = (index) => {
  //     onChange(index + 1); // index starts from 0
  //   };

  return (
    <div className="flex">
      {[0, 1, 2, 3, 4].map((star) => (
        <svg
          key={star}
          className={`w-6 h-6 cursor-pointer ${
            value > star ? "text-yellow-400" : "text-gray-300"
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
          //   onClick={() => handleClick(star)}
        >
          <path d="M10 15l-5.878 3.09 1.119-6.476-4.724-4.062 6.533-.948L10 1l2.95 6.604 6.533.948-4.724 4.062 1.119 6.476z" />
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
