// subServicesActions.js
import {
    fetchSubServicesStart,
    fetchSubServicesSuccess,
    fetchSubServicesFailure,
    createSubServiceStart,
    createSubServiceSuccess,
    createSubServiceFailure,
    updateSubServiceStart,
    updateSubServiceSuccess,
    updateSubServiceFailure,filterSubServ
  } from "../slices/subServiceSlice";
  
  import {
    createSubServiceAPI,
    updateSubServiceAPI,
    fetchSubServicesAPI,
  } from "../../api/services.api";
  
  export const fetchSubServices = () => async (dispatch) => {
    try {
      dispatch(fetchSubServicesStart());
      const subServices = await fetchSubServicesAPI(dispatch);
      dispatch(fetchSubServicesSuccess(subServices));
    } catch (error) {
      dispatch(fetchSubServicesFailure(error.message));
    }
  };
  export const fetchSubCatefories = () => async (dispatch) => {
    try {
      dispatch(fetchSubServicesStart());
      const subServices = await fetchSubServicesAPI(dispatch);
      dispatch(fetchSubServicesSuccess(subServices));
    } catch (error) {
      dispatch(fetchSubServicesFailure(error.message));
    }
  };
  
  export const createSubService = (subServiceData) => async (dispatch) => {
    try {
      dispatch(createSubServiceStart());
      await createSubServiceAPI(subServiceData, dispatch);
      dispatch(createSubServiceSuccess());
    } catch (error) {
      dispatch(createSubServiceFailure(error.message));
    }
  };
  
  export const updateSubService = ( subServiceData) => async (dispatch) => {
    try {
      dispatch(updateSubServiceStart());
      await updateSubServiceAPI(subServiceData,dispatch);
      dispatch(updateSubServiceSuccess());
    } catch (error) {
      dispatch(updateSubServiceFailure(error.message));
    }
  };
  export const filterSubServices = ( serviceID) => async (dispatch) => {
      dispatch(filterSubServ(serviceID))
    };
  
 
  
  