import {fetchAllEntities, createEntity,updateEntity, deleteEntity} from "./baseHalo.api"


export const fetchCompaniesAPI = async (dispatch) => {
  try {
    
    const response = await fetchAllEntities("companies/all", dispatch);
   
    return response;
  } catch (error) {
    throw new Error("Failed to fetch companies: " + error.message);
  }
};

export const createCompanyAPI = async (CompanyData, dispatch) => {
  try {
    const response = await createEntity("companies", CompanyData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create Company: " + error.message);
  }
};

export const updateCompanyAPI = async (CompanyData, dispatch) => {
  try {
    const response = await updateEntity("companies", CompanyData.companyId, CompanyData, dispatch)
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCompanyAPI = async (companyId,dispatch) => {
  try {
    const response = await deleteEntity("companies", companyId, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update Company: " + error.message);
  }
};
export const fetchCompanyAPI = async (companyId,dispatch) => {
  try {
    
    const response = await fetchAllEntities(`companies/${companyId}`, dispatch);
   
    return response;
  } catch (error) {
    throw new Error("Failed to fetch companies: " + error.message);
  }
};