// CompanyAPI.js
import axios from "axios";
import { getAccessTokenFromCookie } from "../utilities/cookieUtils";
import AppConfig from "../config"
import {loadingOn,loadingOff,toggleSidebar } from '../redux/slices/appSlice'

export const fetchAllEntities = async (controller, dispatch, query='') => {

    try {
        dispatch(loadingOn())
        const accessToken = getAccessTokenFromCookie();
        const response = await axios.get(
            `${AppConfig.API_BASE_URL}${controller}/${query}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        dispatch(loadingOff())
        //dispatch??dispatch(loadingOff)
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch ${controller}: ${error.message}`);
    }
};



export const createEntity = async (controller, data, dispatch) => {
    try {
        const accessToken = getAccessTokenFromCookie();
        dispatch(loadingOn())
        debugger;
        const response = await axios.post(
            `${AppConfig.API_BASE_URL}${controller}/`,
            data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        dispatch(loadingOff())
        return response.data;
    } catch (error) {
        throw new Error(`Failed to create ${controller}: ${error.message}`);
    }
};

export const updateEntity = async (controller, id, data, dispatch) => {
    try {
        const accessToken = getAccessTokenFromCookie();
        dispatch(loadingOn())
        debugger;
        const response = await axios.put(
            `${AppConfig.API_BASE_URL}${controller}/${id}`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        dispatch(loadingOff())
        return response.data;
    } catch (error) {
        throw new Error(`Failed to update ${controller}: ${error.message}`);
    }
};

export const deleteEntity = async (controller, id, dispatch) => {
    try {
        const accessToken = getAccessTokenFromCookie();
        dispatch(loadingOn())
        const response = await axios.delete(
            `${AppConfig.API_BASE_URL}${controller}/${id}`,

            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        dispatch(loadingOff())

        return response.data;
    } catch (error) {
        throw new Error(`Failed to update ${controller}: ${error.message}`);
    }
};


export const fetchEntity = async (controller, id,dispatch) => {

    try {
        dispatch(loadingOn())
        const accessToken = getAccessTokenFromCookie();
        const response = await axios.get(
            `${AppConfig.API_BASE_URL}${controller}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        dispatch(loadingOff())
        //dispatch??dispatch(loadingOff)
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch ${controller}: ${error.message}`);
    }
};