import axios from "axios";

import { loginRequest, loginSuccess, loginFailure } from "../slices/loginSlice";
import {
  otpVerifyRequest,
  otpVerifySuccess,
  otpVerifyFailure,
  fetchCompanySuccess,
} from "../slices/verifyOtpSlice";
import {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from "../slices/forgotPasswordSlice";
import { fetchCompanyAPI } from "../../api/companies.api";
import AppConfig from "../../config"

export const login = (credentials) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const url = AppConfig.IS_COMP? 'auth/company/authenticate': 'auth/admin/authenticate';
    
    const response = await axios.post(
      `${AppConfig.API_BASE_URL}${url}`,
      credentials
    );
    dispatch(loginSuccess(response.data));
    console.log("Login Response Data: ", response.data);
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const verifyOtp = (otpData) => async (dispatch) => {
  dispatch(otpVerifyRequest());
  try {
    const url = AppConfig.IS_COMP? 'auth/company/verify-otp': 'auth/admin/verify-otp';
    const response = await axios.post(
      `${AppConfig.API_BASE_URL}${url}`,
      otpData
    );
    dispatch(otpVerifySuccess(response.data));
    localStorage.setItem("tx", response.data.accessToken);
    localStorage.setItem("refreshToken", response.data.refreshToken);
  } catch (error) {
    dispatch(otpVerifyFailure(error.message));
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  dispatch(forgotPasswordRequest());
  try {
    const response = await axios.post(
      `${AppConfig.API_BASE_URL}auth/admin/send-otp`,
      { email }
    );
    dispatch(forgotPasswordSuccess(response.data));
  } catch (error) {
    dispatch(forgotPasswordFailure(error.message));
  }
};

export const fetchCompany = (companyId) => async (dispatch) => {
  try {
    console.log('companyId from auth', companyId)
    const company = await fetchCompanyAPI(companyId,dispatch);
    dispatch(fetchCompanySuccess(company.data));
 
  } catch (error) {
    
  }
};
