// Message.js
import React from "react";
import MessageBox from "../components/MessageBox";
import { Outlet } from "react-router-dom";

const Message = () => {
  // let messageBoxClass = "xl:w-[30%] hidden xl:block ";
  // let outletClass = "w-full xl:w-[calc(69%-16px)] relative xl:absolute right-0";
 
  return (
    <div className="flex flex-col xl:flex-row w-full gap-2 relative h-full ">
      <div className={`xl:w-[30%] hidden xl:block h-[calc(100vh-128px)]`}>
        <MessageBox showProfile />
      </div>
      <div className={`w-full xl:w-[calc(70%-16px)] relative xl:absolute right-0`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Message;
