// App.js
import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

import useAuthGuard from "./utilities/authGaurd";
import { AppContextProvider } from "./config/AppContextProvider";

// Onboarding Screens Imports
import LoginScreen from "./Screens/OnBoarding/LoginScreen";
import ForgotPasswordScreen from "./Screens/OnBoarding/ForgotPasswordScreen";
import VerificationCodeScreen from "./Screens/OnBoarding/VerificationCodeScreen";
import ResetPasswordScreen from "./Screens/OnBoarding/ResetPassword";
import SuccessMessageScreen from "./Screens/OnBoarding/SuccessMessageScreen";
import EmailVerificationCodeScreen from "./Screens/OnBoarding/EmailVerificationCodeScreen";

// Dashboard Screen Imports
import MainLayout from "./Screens/MainLayout";
import Overview from "./Screens/Overview";
import Calendar from "./Screens/Calendar";
import Appointments from "./Screens/Appointments";
import StaffList from "./Screens/Professional/StaffList";
import Messages from "./Screens/Messages";
import PaymentInformation from "./Screens/PaymentInformation";
import Services from "./Screens/Service/Services";
import Offers from "./Screens/Offer/Offers";
import Settings from "./Screens/Settings";
import AddEditService from "./Screens/Service/AddEditService";
import AddEditOffer from "./Screens/Offer/AddEditOffer";
import AddEditCompany from "./Screens/Company/AddEditCompany";
import CompanyList from "./Screens/Company/CompanyList";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import ChatBox from "./components/ChatBox";
import Categories from "./Screens/Category/Category";
import AddEditCategory from "./Screens/Category/AddEditCategory";
import SubCategory from "./Screens/Subcategory/SubCategory";
import AddEditSubCategory from "./Screens/Subcategory/AddEditSubCategory";
import SubService from "./Screens/SubService/SubServices";
import AddEditSubService from "./Screens/SubService/AddEditSubService";
import AddEditStaff from "./Screens/Professional/AddEditStaffList";
import Professions from "./Screens/Profession/Professions";
import TransactionTable from "./components/TransactionTable";
import TransactionDetail from "./components/TransactionDetail";
import TransactionTableTwo from "./components/TransactionTableTwo";

function App() {
  useEffect(() => {
    console.log(
      "Executing something before rendering on every route change..."
    );
  });
  const GuardedRoute = ({ path, element: Element }) => {
    const isAuthenticated = useAuthGuard();
    // const isAuthenticated = true;

    return isAuthenticated ? <Element /> : <Navigate to="/login" />;
  };
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Onboarding Screens */}
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route
            path="/verification-code"
            element={<VerificationCodeScreen />}
          />
          <Route path="/reset-password" element={<ResetPasswordScreen />} />
          <Route path="/success-message" element={<SuccessMessageScreen />} />
          <Route
            path="/email-verification-code"
            element={<EmailVerificationCodeScreen />}
          />

          {/* Dashboard Layout */}
          <Route
            path="/*"
            element={
              <MainLayout>
                <Routes>
                  <Route
                    path="/"
                    element={<GuardedRoute path="/" element={Overview} />}
                  />
                  <Route
                    path="overview"
                    element={
                      <GuardedRoute path="overview" element={Overview} />
                    }
                  />
                  <Route
                    path="calendar"
                    element={
                      <GuardedRoute path="calendar" element={Calendar} />
                    }
                  />
                  <Route
                    path="company-list"
                    element={
                      <GuardedRoute path="company-list" element={CompanyList} />
                    }
                  />
                  <Route
                    path="company-list/new"
                    element={
                      <GuardedRoute
                        path="company-list/new"
                        element={AddEditCompany}
                      />
                    }
                  />
                  <Route
                    path="company-list/edit/:id"
                    element={
                      <GuardedRoute
                        path="company-list/edit/:id"
                        element={AddEditCompany}
                      />
                    }
                  />
                  <Route
                    path="CompanyProfile"
                    element={
                      <GuardedRoute
                        path="CompanyProfile"
                        element={AddEditCompany}
                      />
                    }
                  />
                  <Route
                    path="appointments"
                    element={
                      <GuardedRoute
                        path="appointments"
                        element={Appointments}
                      />
                    }
                  />
                  <Route
                    path="staff-list"
                    element={
                      <GuardedRoute path="staff-list" element={StaffList} />
                    }
                  />
                  <Route
                    path="staff-list/new"
                    element={
                      <GuardedRoute
                        path="company-list/new"
                        element={AddEditStaff}
                      />
                    }
                  />
                  <Route
                    path="staff-list/edit/:id"
                    element={
                      <GuardedRoute
                        path="company-list/edit/:id"
                        element={AddEditStaff}
                      />
                    }
                  />

                  <Route
                    path="messages"
                    element={
                      <GuardedRoute path="messages" element={Messages} />
                    }
                  >
                    <Route
                      path=":id"
                      element={
                        <GuardedRoute path="messages/:id" element={ChatBox} />
                      }
                    />
                  </Route>
                  <Route
                    path="payment-information"
                    element={
                      <GuardedRoute
                        path="payment-information"
                        element={PaymentInformation}
                      />
                    }
                  />
                  <Route
                    path="services"
                    element={
                      <GuardedRoute path="services" element={Services} />
                    }
                  />
                  <Route
                    path="categories"
                    element={
                      <GuardedRoute path="categories" element={Categories} />
                    }
                  />
                  <Route
                    path="categories/new"
                    element={
                      <GuardedRoute
                        path="categories/new"
                        element={AddEditCategory}
                      />
                    }
                  />
                  <Route
                    path="categories/edit/:id"
                    element={
                      <GuardedRoute
                        path="categories/edit/:id"
                        element={AddEditCategory}
                      />
                    }
                  />

                  <Route
                    path="subCategories"
                    element={
                      <GuardedRoute
                        path="subCategories"
                        element={SubCategory}
                      />
                    }
                  />
                  <Route
                    path="subCategories/new"
                    element={
                      <GuardedRoute
                        path="subCategories/new"
                        element={AddEditSubCategory}
                      />
                    }
                  />
                  <Route
                    path="subCategories/edit/:id"
                    element={
                      <GuardedRoute
                        path="subCategories/edit/:id"
                        element={AddEditSubCategory}
                      />
                    }
                  />
                  <Route
                    path="subServices"
                    element={
                      <GuardedRoute path="subServices" element={SubService} />
                    }
                  />
                  <Route
                    path="subServices/new"
                    element={
                      <GuardedRoute
                        path="subServices/new"
                        element={AddEditSubService}
                      />
                    }
                  />
                  <Route
                    path="subServices/edit/:id"
                    element={
                      <GuardedRoute
                        path="subServices/edit/:id"
                        element={AddEditSubService}
                      />
                    }
                  />
                  <Route
                    path="offers"
                    element={<GuardedRoute path="offers" element={Offers} />}
                  />
                  <Route
                    path="settings"
                    element={
                      <GuardedRoute path="settings" element={Settings} />
                    }
                  />
                  <Route
                    path="services/new"
                    element={
                      <GuardedRoute
                        path="services/new"
                        element={AddEditService}
                      />
                    }
                  />
                  <Route
                    path="services/edit/:id"
                    element={
                      <GuardedRoute
                        path="services/edit/:id"
                        element={AddEditService}
                      />
                    }
                  />
                  <Route
                    path="offers/new"
                    element={
                      <GuardedRoute path="offers/new" element={AddEditOffer} />
                    }
                  />
                  <Route
                    path="offers/edit/:id"
                    element={
                      <GuardedRoute
                        path="offers/edit/:id"
                        element={AddEditOffer}
                      />
                    }
                  />
                  <Route
                    path="profession"
                    element={
                      <GuardedRoute path="offers" element={Professions} />
                    }
                  />

                  {/* adjust these pages according to your need */}
                  <Route
                    path="/transaction-table-two"
                    element={<TransactionTableTwo />}
                  />
                  <Route
                    path="/transaction-table"
                    element={<TransactionTable />}
                  />
                  <Route path="/detail/:id" element={<TransactionDetail />} />
                </Routes>
              </MainLayout>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
