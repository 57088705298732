import React, { useState,useEffect } from "react";
import { AppIcons } from "../assets";
// import Dropdown from "./Dropdown";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { fetchImage } from "../api/imageUpload.api";
import { AppImages } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { selectProfessions } from "../redux/slices/professionsSlice";
import { fetchProfessions } from "../redux/actions/professionsActions";

const ProfileCard = ({
  name,
  role,
  image,
  imageAlt,
  onProfileClick,
  userStatus,
  setUserStatus,
  profileInfo,
}) =>{
  // const [image, setImage] = useState('');
  // useEffect(() => {
  //   fetchImage(imageUrl)
  //     .then((img) => setImage(img))

  // }, [imageUrl]);
  return  (
    <div className="max-w-xs mx-auto text-center">
      <div className="flex flex-col items-center gap-2">
        <div className="flex items-center gap-2">
          <img
            src={image}
            alt={AppImages.Placeholder}
            className="w-28 h-28 object-cover rounded-full"
          />
          {/* <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Dropdown Button
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
        </div>
        <div className="flex flex-col items-start">
          <h2 className="text-xl font-bold mb-2">{name}</h2>
          <p className="text-gray-500">{role}</p>
        </div>
      </div>
    </div>
  );
} 

const HistoryCard = ({ status, date, user, service, onHistoryClick }) => (
  <div
    className="flex justify-between items-center w-full mt-2 p-2 bg-white"
    onClick={() => onHistoryClick(status, date, user, service)}
  >
    <div className="items-start">
      <h3 className="text-base font-bold">{user}</h3>
      <p className="text-sm text-gray-500">
        {service} - {date}
      </p>
    </div>
    <p
      className={`text-base font-bold ${
        status === "Done"
          ? "text-green-500"
          : status === "In work"
          ? "text-orange-500"
          : "text-red-500"
      }`}
    >
      {status}
    </p>
  </div>
);

const UserProfileBoard = ({ selectedUser, image,onProfileClick, onHistoryClick }) => {
  const [userStatus, setUserStatus] = useState("Available");
  const navigate = useNavigate()
  const { professions } = useSelector(selectProfessions);
  const dispatch = useDispatch();
  useEffect(() => {
    if(professions.length===0)
    dispatch(fetchProfessions())
  }, [dispatch]); 
  const profileInfo = {
    name: selectedUser.firstName +' ' + selectedUser.lastName,
     role: selectedUser.role?.name,
    imageUrl: selectedUser.profileImage,
    imageAlt: `${selectedUser.firstName} Profile Image`,
  };
  const handleAddProfessionClick = ()=>{
    navigate("/staff-list/new", { state: { professionList:professions.filter(x=>x.isActive===true)} });
  }
  const editStaffClick=(staffItem) => {
    navigate(`/staff-list/edit/${staffItem.id}`,  { state: {staff:staffItem, professionList:professions.filter(x=>x.isActive===true)} });
  };
  const workHistory = selectedUser.workHistory || [];
  return (
    <div className="fixed top-20 right-0 w-80 max-h-full overflow-y-auto m-4 p-4 bg-white rounded-[10px] shadow-sm no-scrollbar h-[calc(100%-120px)]">
      <div className="flex gap-1 justify-between">
      <button
                    className="justify-center px-3 py-2 mb-4 text-base text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
                    onClick={handleAddProfessionClick}
                >
                    Add a Professional
                </button>
        <h2 className="text-lg font-bold max-md:max-w-full"></h2>
        
        <button className="flex items-center justify-center bg-white rounded-full w-10 h-10"
          onClick={() => editStaffClick(selectedUser)}>
          <img loading="lazy" src={AppIcons.Edit} alt="" />
        </button>
      </div>
      <ProfileCard
        profileInfo={profileInfo}
        {...profileInfo}
        image={image}
        onProfileClick={onProfileClick}
        userStatus={userStatus}
        setUserStatus={setUserStatus}
      />
      <div className="border-t border-b border-[#DFDFDF] pt-14 pb-9">
        <h2 className="text-[22px] font-bold pb-7">Personal Information</h2>
        <p className="text-[14px] font-medium mt-2 m-0">Phone</p>
        <p className="mb-3">{selectedUser.phone?.countryCode+selectedUser.phone?.phoneNumber}</p>
        <p className="text-[14px] font-medium m-0">Email</p>
        <p className="mb-3">{selectedUser.email}</p>
        <p className="text-[14px] font-medium m-0">Personal Email</p>
        <p className="mb-3">{selectedUser.personalEmail}</p>
        <p className="text-[14px] font-medium m-0">Is Active</p>
        <p className="mb-3">{selectedUser.isActive==true?'Active':'InActive'}</p>
      </div>
      <div>
        <h2 className="text-2xl mt-2">Work History</h2>
        {/* {workHistory.map((item, index) => (
          <HistoryCard
            key={index}
            {...item}
            onHistoryClick={() =>
              onHistoryClick(item.status, item.date, item.user, item.service)
            }
          />
        ))} */}
      </div>
    </div>
  );
};

export default UserProfileBoard;
