// otpSlice.js
import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

const initialState = {
  loading: false,
  error: null,
  data: null,
  companyData: null
};

export const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState,
  reducers: {
    otpVerifyRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    otpVerifySuccess: (state, action) => {
      console.log("verify otp action payload:", action.payload);
      state.loading = false;
      state.error = action.payload.error;
      state.data = action.payload.data;
      Cookies.set('tx', action.payload.data.accessToken);
      
    },
    otpVerifyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCompanySuccess(state, action) {
      console.log(action.payload.data)
      state.companyData = action.payload;
      state.loading = false;
      state.success = true;
    },
  },
});

export const { otpVerifyRequest, otpVerifySuccess, otpVerifyFailure,fetchCompanySuccess } =
verifyOtpSlice.actions;
export const selectOTP = (state) => state.verifyOtp;
export default verifyOtpSlice.reducer;
