import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppIcons, AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { useDispatch, useSelector } from "react-redux";
// import { selectLogin } from "../../redux/slices/loginSlice";
import { createCompany, deleteCompany, updateCompany } from "../../redux/actions/companiesActions";
import { uploadImage, fetchImage } from "../../api/imageUpload.api";
import { fetchCategoriesAPI } from "../../api/services.api";
import DropdownSelector from "../../components/DropDownSelector";


function CompanyImage({ companyPicture, onChangeCompanyPicture }) {
    return (
        <div className="flex gap-3 justify-between self-start mt-8 font-semibold tracking-widest text-center text-white max-md:mt-5">
            <img
                loading="lazy"
                src={companyPicture || AppImages.Placeholder}
                alt="Company Image"
                className="shrink-0 max-w-full rounded-xl border-solid aspect-rectangle border-[7px] border-zinc-100 w-[250px]"
            />
            <button
                onClick={onChangeCompanyPicture}
                className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
            >
                Change Picture
            </button>
        </div>
    );
}

function AddEditCompany() {
    const location = useLocation();
    const company = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [companyName, setCompanyName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [category, setCategory] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [aptNo, setAptNo] = useState("");
    const [defAddrId, setDefAddrId] = useState(0);
    const [doe, setDoe] = useState("");
    const [description, setDescription] = useState("");
    const [companyPicture, setCompanyPicture] = useState(null);
    const [companyPictureUrl, setCompanyPictureUrl] = useState(null)
    const [imageUpdate, setimageUpdate] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [categories, setCategories] = useState([])


    const center = {
        lat: 25.276987,
        lng: 55.296249,
    };
    useEffect(() => {
        if (company) {
            setCompanyName(company.name);
            setPhone(company.phone.phoneNumber);
            setDescription(company.description);
            setEmail(company.email)
            setDoe(company.doe)
            setIsActive(company.isActive)
            setCategoryId(company.category)
            if (company.companyImageBG)
                fetchImage(company.companyImageBG)
                    .then((img) => setCompanyPictureUrl(img))


            if (company.addresses && company.addresses.length > 0) {
                let defAddr = company.addresses[0];
                setCity(defAddr.city);
                setPostalCode(defAddr.postalCode)
                setStreet(defAddr.street)
                setAptNo(defAddr.aptNo)
                setDefAddrId(defAddr._id)
            }
            const fetchCategories = async () => {
               
                    let res = await fetchCategoriesAPI(dispatch);
                    setCategory(res.data.find(x => x.id === company.category))
                    setCategories(res.data);
                
               


            };
            fetchCategories();
        }
    }, [company]);
    useEffect(() => {
        const fetchCategories = async () => {
            let res = await fetchCategoriesAPI(dispatch);

            setCategories(res.data);
            debugger;
            if (categoryId)
                setCategory(res.data.find(x => x.id === categoryId))
        };
        fetchCategories();

    }, [])

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleDoeChange = (e) => {
        setDoe(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleDeleteCompanyClick = () => {
        dispatch(deleteCompany(company.companyId))
        navigate(-1)
    };

    const handleChangeCompanyPicture = () => {
        pickFile([FileTypes.IMAGE], (result) => {
            // Handle the selected file result here
            console.log(result);
            setimageUpdate(true)
            setCompanyPicture(result);
            setCompanyPictureUrl(URL.createObjectURL(result))
        });
    };
    const handleActiveToggle = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    }
    const handleSave = async () => {
        const addressData = {
            category: category.id,
            city: city,
            street: street,
            aptNo: aptNo,
            postalCode: postalCode,
            country: "UAE",
            latitude: center.lat,
            longitude: center.lng,
        };
        //Update Case
        if (company) {
            if (imageUpdate) {
                await uploadImage('companyList', 'companyImage', companyPicture).then((imageReturn) => {
                    dispatch(updateCompany({
                        companyId: company.companyId,
                        companyImageBG: imageReturn.data.path,
                        name: companyName,
                        isActive: isActive,
                        email: email,
                        category: category.id,
                        doe: doe,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone,
                            _id: company.phone._id
                        },

                    }, { _id: defAddrId, ...addressData }
                    ))
                })
            }
            else {
                dispatch(updateCompany({
                    companyId: company.companyId,
                    name: companyName,
                    isActive: isActive,
                    email: email,
                    category: category.id,
                    doe: doe,
                    phone: {
                        countryCode: '+971',
                        phoneNumber: phone,
                        _id: company.phone._id
                    },

                }, { _id: defAddrId, ...addressData }))
            }

        }
        //New Case
        else {
            if (imageUpdate) {
                await uploadImage('companyList', 'companyImage', companyPicture).then((imageReturn) => {
                    dispatch(createCompany({
                        companyImageBG: imageReturn.data.path,
                        doe: doe,
                        category: category.id,
                        name: companyName,
                        isActive: isActive,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone

                        }
                    }, { ...addressData }))
                })
            }
            else {
                dispatch(createCompany({
                    name: companyName,
                    doe: doe,
                    category: category.id,
                    isActive: isActive,
                    phone: {
                        countryCode: '+971',
                        phoneNumber: phone

                    }
                }, { ...addressData }))
            }
        }


        navigate(-1)

        // Implement save logic here console.log("Save button clicked");
    };
    const handleCancel = () => {
        // Implement cancel logic here console.log("Cancel button clicked");
    };

    return (
        <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">

            <header className="flex gap-5 justify-between font-bold max-md:flex-wrap max-md:max-w-full">
                <button
                    className="w-31 h-77 px-2 rounded-md flex-shrink-0 rounded-10 bg-blue-100"
                    onClick={() => navigate(-1)}
                >
                    <img loading="lazy" src={AppIcons.ChevronRight} alt="Profile Image" />
                </button>
                {company ? <button
                    className="justify-center px-3 py-2 text-sm text-white rounded-lg bg-[#FC2C5A] tracking-[2px] max-md:px-5"
                    onClick={handleDeleteCompanyClick}
                >
                    Delete Company
                </button> : <div></div>}

            </header>

            <CompanyImage
                companyPicture={companyPictureUrl}
                onChangeCompanyPicture={handleChangeCompanyPicture}
            />
            <h4 className="mt-4 text-xl">Personal Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="Name"
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    placeholder="Enter Company Name"
                />
                <FieldInput
                    label="Phane"
                    type="tel"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="+971 (----------)"
                />
                <FieldInput
                    label="Email"
                    type="Email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter Company Email"
                />
                {categories && (
                    <DropdownSelector
                        label="Category"
                        items={categories}
                        selectedItem={category}
                        onSelect={setCategory}
                    />)}

            </div>
            <h4 className="mt-4 text-xl">Address Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="City"
                    value={city}
                    onChange={(obj) => setCity(obj.target.value)}
                    placeholder="Enter City"
                />
                <FieldInput
                    label="Postal Code"
                    type="number"
                    value={postalCode}
                    onChange={(obj) => setPostalCode(obj.target.value)}
                    placeholder="Enter Postal Code"
                />
                <FieldInput
                    label="Street"
                    value={street}
                    onChange={(obj) => setStreet(obj.target.value)}
                    placeholder="Enter Street"
                />

            </div>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="Apartment Number"
                    type="number"
                    value={aptNo}
                    onChange={(obj) => setAptNo(obj.target.value)}
                    placeholder="Enter Apartment Number"
                />
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>

            </div>
            <h4 className="mt-4 text-xl">Expiry/Activation</h4>
            <div className="flex gap-3 items-start mt-2  tracking-widest max-md:flex-wrap max-md:max-w-full">

                <div className="flex flex-col  mt-3 text-sm  font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Expiry Date"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Expiry Date
                    </label>




                    <div class="">
                        <div className=" grow justify-center items-start px-4 py-6 mt-1.5  rounded-3xl border border-solid border-zinc-400  max-md:px-5 max-md:max-w-full">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <StaticDatePicker value={dayjs(doe)}
                                    onChange={(newValue) => setDoe(newValue)} label="Expiry Date" />

                            </LocalizationProvider>

                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full items-end">
                    <FieldInput
                        label="Active/Inactive"
                        value={isActive}
                        onChange={handleActiveToggle}
                        type="switch"
                        placeholder="Active"
                    />
                    <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                        <button
                            onClick={handleSave}
                            className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
                        >
                            {company ? "Save" : "Add Company"}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>



            </div>


        </section>
    );
}

export default AddEditCompany;
