// servicesAPI.js
import axios from "axios";
import { getAccessTokenFromCookie } from "../utilities/cookieUtils";

import {fetchAllEntities, createEntity,updateEntity, deleteEntity} from "./baseHalo.api"
import AppConfig from "../config"

export const fetchSubServicesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/services/subservices/all", dispatch);
    
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
export const fetchServicesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/services/all", dispatch);
    
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
export const fetchSubCategoriesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/all", dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
export const fetchCategoriesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories", dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
export const createCategoryAPI = async (data, dispatch) => {
  try {
    const response = await createEntity("categories", data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
};
export const createSubCategoryAPI = async (data, dispatch) => {
  try {
    const response = await createEntity(`categories/${data.catId}/subcategories`, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
}
export const createSubServiceAPI = async (data, dispatch) => {
  try {
    const response = await createEntity(`categories/subcategories/services/${data.serviceId}/subservices`, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create sub service: " + error.message);
  }
};;
export const createServiceAPI = async (serviceData, dispatch) => {
  try {
    const response = await createEntity(`categories/subcategories/${serviceData.subCatId}/services`, serviceData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
};
export const updateSubServiceAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories/services/subServices", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update sub service: " + error.message);
  }
};
export const updateServiceAPI = async (serviceData, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories/services", serviceData.id, serviceData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};
export const updateCategoryAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};
export const updateSubCategoryAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};
