
import {
    fetchStaffListStart,
    fetchStaffListSuccess,
    fetchStaffListFailure,
    createStaffStart,
    createStaffSuccess,
    createStaffFailure,
    updateStaffStart,
    updateStaffSuccess,
    updateStaffFailure,
    deleteStaffFailure
  } from "../slices/staffListSlicer";
  import { createCompanyUserAddressAPI, updateCompanyUserAddressAPI } from "../../api/companyUserAddress.api.js";

import { useContext } from "react";
  
  import {
    createStaffListAPI,
    updateStaffListAPI,
    fetchStaffListAPI,
    deleteStaffListAPI
  } from "../../api/staffList.api";

  
  
  export const fetchStaffList = (companyId) => async (dispatch) => {
    try {
      dispatch(fetchStaffListStart());
      const staffList = await fetchStaffListAPI(companyId, dispatch);
      dispatch(fetchStaffListSuccess(staffList));
   
    } catch (error) {
      dispatch(fetchStaffListFailure(error.message));
    }
  };
  export const deleteStaff = (id) => async (dispatch) => {
    try {
      dispatch(fetchStaffListStart());
       await deleteStaffListAPI(id,dispatch);
   
    } catch (error) {
        dispatch(deleteStaffFailure(error.message));
    }
  };
  export const createStaff = (staffData,addrData) => async (dispatch) => {
    try {
      dispatch(createStaffStart);
      let res = await createStaffListAPI(staffData,dispatch);
      await createCompanyUserAddressAPI(addrData, res.data.id, dispatch)
      
      dispatch(createStaffSuccess());
    } catch (error) {
      dispatch(createStaffFailure(error.message));
    }
  };
  
  export const updateStaff = (staffData,addrData) => async (dispatch) => {
    try {
      debugger;
      dispatch(updateStaffStart());
      let res = await updateStaffListAPI(staffData,dispatch);
      let updatedAddress={}
      if(addrData._id===0)
      {
        delete addrData._id;
        updatedAddress =await createCompanyUserAddressAPI(addrData, res.data.id, dispatch)
      }
      else
      {
        updatedAddress =await updateCompanyUserAddressAPI(addrData,res.data.id,dispatch);
      }
      res.data.addresses = [updatedAddress.data]
      dispatch(updateStaffSuccess(res));
    } catch (error) {
      dispatch(updateStaffFailure(error.message));
    }
  };


  