import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const DropdownSelector = ({ label, items = [],disabled, selectedItem, onSelect }) => {
  return (
    <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
      <label
        htmlFor="DropdownSelector"
        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
      >
        {label}
      </label>
      <div className="grow justify-center items-start px-1 py-2 mt-1.5 w-full rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
        <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
          <Dropdown.Toggle
            className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none"
            variant="success"
            id="dropdown-basic" disabled={disabled}
          >
            {selectedItem == null ? `Select ${label}` : selectedItem.name}
          </Dropdown.Toggle>

          <Dropdown.Menu className="btn-dropdown-list-halo">
            {/* {items.map((item, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => onSelect(item)}
              >
                {item.name}
              </Dropdown.Item>
            ))} */}
            {items.length > 0 ? (
              items.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => onSelect(item)}
                >
                  {item.name}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>No items available</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DropdownSelector;
