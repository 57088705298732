// MainLayout.js
import React from "react";
import Header from "../components/Header";
import DashboardPanel from "../components/DashboardPanel";
import { useSelector } from "react-redux";
import { selectApp } from "../redux/slices/appSlice";
import { Spinner } from 'react-bootstrap';

const MainLayout = ({ children }) => {
  const { isAppLoading } = useSelector(selectApp);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#FAFBFF",
      }}
    >
      <Header />
      <div className="flex flex-row flex-1 pt-20">
  <DashboardPanel />
  <div className="flex-1 pl-0 xl:pl-[290px] mx-4 my-4 h-[100%-80px] overflow-y-auto">
    <div style={{ display: isAppLoading ? 'none' : 'block' }}>
      {children}
    </div>
    {isAppLoading && (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )}
  </div>
</div>
</div>
  );
};

export default MainLayout;
