// companiesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {},
  companies: [],
  loading: false,
  creating: false,
  updating: false,
  error: null,
  success: false,
};

export const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
      fetchCompaniesStart(state) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      fetchCompaniesSuccess(state, action) {
        state.companies = action.payload.data;
        state.loading = false;
        state.success = true;
      },
      fetchCompaniesFailure(state, action) {
        state.loading = false;
        state.error = action.payload.error;
        state.success = false;
      },
      createCompanyStart(state) {
        state.creating = true;
        state.error = null;
        state.success = false;
      },
      createCompanySuccess(state, action) {
        state.company = action.payload.data;
        state.creating = false;
        state.success = true;
      },
      createCompanyFailure(state, action) {
        state.creating = false;
        state.error = action.payload.error;
        state.success = false;
      },
      updateCompanyStart(state) {
        state.updating = true;
        state.error = null;
        state.success = false;
      },
      updateCompanySuccess(state, action) {
        debugger
        state.company = action.payload.data;
        state.updating = false;
        state.success = true;
      },
      updateCompanyFailure(state, action) {
        state.updating = false;
        state.error = action.payload.error;
        state.success = false;
      },
      deleteCompanyFailure(state, action) {
        state.updating = false;
        state.error = action.payload.error;
        state.success = false;
      },
    },
  });

  export const {
    fetchCompaniesStart,
    fetchCompaniesSuccess,
    fetchCompaniesFailure,
    createCompanyStart,
    createCompanySuccess,
    createCompanyFailure,
    updateCompanyStart,
    updateCompanySuccess,
    updateCompanyFailure,
    deleteCompanyFailure,
  } = companiesSlice.actions;
  
  export const selectCompanies = (state) => state.companies;
  
  export default companiesSlice.reducer;