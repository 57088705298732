// servicesActions.js
import {
  ProfessionsAPIStart,
  getProfessionsSuccess,
  ProfessionsAPIFailure,
} from "../slices/professionsSlice";

import {
  fetchAllEntities
} from "../../api/baseHalo.api";

import AppConfig from "../../config";

export const fetchProfessions = () => async (dispatch) => {
  try {
    dispatch(ProfessionsAPIStart());
    const professions = await fetchAllEntities(AppConfig.endpoints.PROFESSIONS.LIST, dispatch);
    console.log("fetchProfessions", professions)
    dispatch(getProfessionsSuccess(professions));
  } catch (error) {
    dispatch(ProfessionsAPIFailure(error.message));
  }
};
