import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfessions } from "../../redux/actions/professionsActions";
import { selectProfessions } from "../../redux/slices/professionsSlice";
import { AppIcons, AppImages } from "../../assets";
import "../../styles/services.style.css";
import {fetchImage} from '../../api/imageUpload.api'

function ProfessionCard({ profession, onEdit }) {





  return (
    <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-3 py-3 font-bold rounded-2xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-4">
        <div className="flex gap-5 justify-between w-full text-base tracking-widest text-black max-md:mr-2.5">
          <div className="flex gap-1 justify-between">
            
            <div className="my-auto text-black ml-2 font-bold text-sm leading-normal tracking-wider">
              {profession?.name}
            </div>
          </div>
          <button
            className="shrink-0 my-auto aspect-square w-[21px]"
            onClick={() => onEdit(profession)}
          >
            <img loading="lazy" src={AppIcons.Edit} alt="" />
          </button>
        </div>
        
        {/* <div className="mt-2 text-base tracking-widest bg-clip-text amount">
          AED
          {category.pricePerHour}
        </div> */}
        <div className="mt-2 text-sm leading-6 text-black description">
          {profession?.description}
        </div>
      </div>
    </div>
  );
}

function Professions() {
  const {  professions } = useSelector(selectProfessions);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfessions());
  }, [dispatch]);

  function handleAddProfession() {
    console.log("Adding a new category");
    //navigate("/categories/new");
  }

  function handleEditProfession(profession) {
    //navigate(`/categories/edit/${category?.id}`, { state: category });
  }

  return (
    <section className="flex flex-col px-4 pt-7 pb-20 bg-white rounded-2xl shadow-sm max-md:px-4 max-md:mt-10 max-md:max-w-full">
      <header className="flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
        <h2 className="flex-auto my-auto text-3xl text-black tracking-[2.4px] heading">
         
        </h2>
        <button
          onClick={handleAddProfession}
          className="justify-center px-3 py-2  text-sm text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
        >
          Add a new Professional
        </button>
      </header>
      <div className="mt-8 max-md:mb-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-2 max-md:flex-col max-md:gap-0">
          {professions.map((profession, index) => (
            <ProfessionCard
              key={index}
              profession={profession}
              onEdit={handleEditProfession}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Professions;
