// subServicesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subService: {},
  subServices: [],
  allSubServices : [],
  loading: false,
  creating: false,
  updating: false,
  error: null,
  success: false,
};

const subServicesSlice = createSlice({
  name: "subServices",
  initialState,
  reducers: {
    filterSubServ(state,action) {
        state.subServices=state.allSubServices.filter(x=>x.service == action.payload)
      },
    fetchSubServicesStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    
    fetchSubServicesSuccess(state, action) {
      state.subServices = action.payload.data;
      state.allSubServices = action.payload.data
      state.loading = false;
      state.success = true;
    },
    fetchSubServicesFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.success = false;
    },
    createSubServiceStart(state) {
      state.creating = true;
      state.error = null;
      state.success = false;
    },
    createSubServiceSuccess(state, action) {
      state.subService = action.payload.data;
      state.creating = false;
      state.success = true;
    },
    createSubServiceFailure(state, action) {
      state.creating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    updateSubServiceStart(state) {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateSubServiceSuccess(state, action) {
      state.subService = action.payload.data;
      state.updating = false;
      state.success = true;
    },
    updateSubServiceFailure(state, action) {
      state.updating = false;
      state.error = action.payload.error;
      state.success = false;
    },
  },
});

export const {
  fetchSubServicesStart,
  fetchSubServicesSuccess,
  fetchSubServicesFailure,
  createSubServiceStart,
  createSubServiceSuccess,
  createSubServiceFailure,
  updateSubServiceStart,
  updateSubServiceSuccess,
  updateSubServiceFailure,
  filterSubServ
} = subServicesSlice.actions;

export const selectSubServices = (state) => state.subServices;

export default subServicesSlice.reducer;
