import {fetchAllEntities, createEntity,updateEntity, deleteEntity} from "./baseHalo.api"
import AppConfig from "../config"



export const fetchStaffListAPI = async (companyId, dispatch) => {
  try {
    const url = AppConfig.IS_COMP? `company/${companyId}/professionals` :"company/professionals/all"
    const response = await fetchAllEntities(url, dispatch);
    
    console.log(response)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch Staff List: " + error.message);
  }
};

export const createStaffListAPI = async (StaffData ,dispatch) => {
  try {
    const response = await createEntity(`company/${StaffData.company}/professionals`, StaffData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create Staff: " + error.message);
  }
};

export const updateStaffListAPI = async (StaffData,dispatch) => {
  try {
    const response = await updateEntity(`company/professionals`, StaffData.id, StaffData, dispatch)
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteStaffListAPI = async (staffId,dispatch) => {
  try {
    const response = await deleteEntity("company/professionals", staffId, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update Company: " + error.message);
  }
};
