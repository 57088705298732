// appSlice.js
import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isSidebarOpen: false,
    isAppLoading : false
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    loadingOn : (state)=>{
      console.log('loadingOn')
      state.isAppLoading = true
    },
    loadingOff : (state)=>{
      state.isAppLoading = false
      console.log('loadingOff')
    }
  },
});

export const { toggleSidebar, loadingOn,loadingOff } = appSlice.actions;
export const selectApp = (state) => state.app;
export default appSlice.reducer;
