import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { fetchBookingsAPI } from "../api/bookings.api";

const statusColors = {
  Start: "linear-gradient(91deg,#01A0AA 1.65%,#076B9E 100%)",
  Started: "linear-gradient(91deg,#01A0AA 1.65%,#076B9E 100%)",
  Completed: "linear-gradient(276deg,#F0BA47 8.16%,#F3D25C 63.6%)",
  Cancelled: "#FC2C5A",
};

const ITEMS_PER_PAGE = 10;

function Appointments() {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    customerName: "",
    status: "",
    bookingId:"",
    professionalName:"",
    Service:"",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBookingsAPI(
          currentPage,
          ITEMS_PER_PAGE,
          dispatch
        );
        setAppointments(response.data.bookings);
        setTotalPages(Math.ceil(response.data.totalBookings / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="overflow-auto rounded-xl shadow-sm p-8 bg-white">
      <div className="mb-4 flex gap-4">
      
      <input
          type="text"
          name="bookingId"
          placeholder="Booking Id"
          value={filters.bookingId}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
      <input
          type="text"
          name="professionalName"
          placeholder="Professional Name"
          value={filters.bookingId}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="customerName"
          placeholder="Customer Name"
          value={filters.customerName}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="professionalName"
          placeholder="Professional Name"
          value={filters.professionalName}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        
        <select
          name="status"
          value={filters.status}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        >
          <option value="">Filter by Status</option>
          <option value="0">Pending</option>
          <option value="1">Started</option>
          <option value="2">Completed</option>
          <option value="3">Cancelled</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Booking Id</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((booking) => (
                <BookingRow key={booking._id} booking={booking} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="flex justify-between mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 rounded"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
}

function BookingRow({ booking }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{booking._id}</TableCell>
        <TableCell>{`${booking.customer.firstName || ""} ${booking.customer.lastName || ""}`}</TableCell>
        <TableCell>{booking.startTime}</TableCell>
        <TableCell>{booking.endTime}</TableCell>
        <TableCell>{booking.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Sub-Booking ID</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Professional Name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Services</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {booking.subBookings.map((subBooking) => (
                  <SubBookingRow key={subBooking._id} subBooking={subBooking} />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SubBookingRow({ subBooking }) {
  return (
    <TableRow className="sub-booking-row">
      <TableCell>{subBooking._id}</TableCell>
      <TableCell>{subBooking.professional ? `${subBooking.professional.firstName} ${subBooking.professional.lastName}` : "Unknown"}</TableCell>
      <TableCell>
        {subBooking.services.map((service) => service.service.name).join(", ")}
      </TableCell>
      <TableCell>{subBooking.status}</TableCell>
    </TableRow>
  );
}

export default Appointments;
