import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices, filterServicesAction } from "../../redux/actions/servicesActions";
import { selectServices } from "../../redux/slices/servicesSlice";
import { AppIcons, AppImages } from "../../assets";
import "../../styles/services.style.css";
import { fetchImage } from '../../api/imageUpload.api'
import { selectSubCategories } from "../../redux/slices/subCategorySlice";
import { selectCategories } from "../../redux/slices/categorySlice"
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchSubCategories } from "../../redux/actions/subCategoryAction";
import { fetchCategories } from "../../redux/actions/categoryActions";

function ServiceCard({ catName, subCatName, service, onEdit }) {


  const { icon } = service;
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    fetchImage(icon)
      .then((img) => setImageUrl(img))

  }, [icon]);




  return (
    <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-3 py-3  rounded-2xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-4">
        <div className="flex gap-5 justify-between w-full text-base tracking-widest text-black max-md:mr-2.5">
          <div className="flex gap-1 justify-between">
            <img
              loading="lazy"
              src={imageUrl}
              alt={service.name}
              className="flex-shrink-0 rounded-full aspect-square w-[59px]"
            />
            <div className="my-auto text-black ml-2 font-bold text-sm leading-normal tracking-wider">
              {service.name}
            </div>
          </div>
          <button
            className="shrink-0 my-auto aspect-square w-[21px]"
            onClick={() => onEdit(service)}
          >
            <img loading="lazy" src={AppIcons.Edit} alt="" />
          </button>
        </div>
        <div className="flex flex-col mt-4">

          <div className="flex text-sm flex-row justify-between">
            <p className="text-gray-500">Category</p>
            <p>{catName}</p>
          </div>
          <div className="flex flex-row text-sm justify-between">
            <p className="text-gray-500">Sub Category</p>
            <p>{subCatName}</p>
          </div>
        </div>

        {/* <div className="mt-2 text-base tracking-widest bg-clip-text amount">
          AED
          {service.pricePerHour}
        </div> */}
        <div className="mt-2 text-sm leading-6 text-black description">
          {service.description}
        </div>
      </div>
    </div>
  );
}

function Services() {
  const { loading, error, services } = useSelector(selectServices);
  const { subCategories } = useSelector(selectSubCategories);
  const { categories } = useSelector(selectCategories);
  const [subCategory, setSubCategory] = useState(null)
  const [category, setCategory] = useState(null)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchSubCategories());
    dispatch(fetchServices());
  }, [dispatch]);

  function handleAddService() {
    console.log("Adding a new service");
    navigate("/services/new");
  }

  function handleEditService(service) {
    console.log("Editing service:", service);
    navigate(`/services/edit/${service?.id}`, { state: service });
  }
  const subCategorySelected = (obj) => {
    setSubCategory(obj)
    dispatch(filterServicesAction(obj.id))
    // subCategories = subCategories.filter(x=>x.category == obj.id)
    console.log(subCategories)
  }
  const categorySelected = (obj) =>{
    setCategory(obj)
  }

  return (
    <section className="flex flex-col px-4 pt-7 pb-20 bg-white rounded-2xl shadow-sm max-md:px-4 max-md:mt-10 max-md:max-w-full">
      <header className=" logo-nav-flexbox-container flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {category == null ? 'Select Category' : category.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {categories.map((category, index) => (
                  <Dropdown.Item onClick={
                    () => {
                      categorySelected(category);
                    }

                  } >{category.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Sub Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {subCategory == null ? 'Select Sub Category' : subCategory.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {subCategories.filter(x=>x.category==category?.id).map((subCategory, index) => (
                  <Dropdown.Item onClick={
                    () => {
                      subCategorySelected(subCategory);
                    }

                  } >{subCategory.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <button
          onClick={handleAddService}
          className="justify-center px-3 py-2  text-sm text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
        >
          Add a new Service
        </button>
      </header>
      <div className="mt-8 max-md:mb-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-2 max-md:flex-col max-md:gap-0">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              catName={categories.length>0&& subCategories.length>0 ?categories.filter(x => x.id == subCategories.filter(y => y.id == service.subCategory)[0].category)[0].name: ''}
              subCatName={subCategories.length>0?subCategories.filter(x => x.id == service.subCategory)[0].name: ''}
              service={service}

              onEdit={handleEditService}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
