// Overview.js
import React, { useEffect, useState } from "react";
import Appointments from "./Appointments";
import MessageBox from "../components/MessageBox";
import { fetchAllStats } from "../api/bookings.api";
import { useDispatch } from "react-redux";
import { AppIcons } from "../assets";
import ReactEcharts from "echarts-for-react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import DoubleLineChart from "../components/DoubleLineChart";
import StarRating from "../components/StarRating";
const overviewData = [
  {
    name: "All Clients",
    count: "123",
  },
  {
    name: "All Appointments",
    count: "123",
  },
  {
    name: "All Services",
    count: "123",
  },
  {
    name: "All Staffs",
    count: "123",
  },
];

const pieData = [
  { name: "Company A", value: 30 },
  { name: "Company B", value: 40 },
  { name: "Company C", value: 20 },
  { name: "Company D", value: 10 },
];

const customers = [
  { name: "Jane Cooper", id: "ID58723635", bookings: 12 },
  { name: "John Doe", id: "ID12345678", bookings: 5 },
  { name: "Alice Smith", id: "ID87654321", bookings: 3 },
  { name: "Michael Lee", id: "ID24680246", bookings: 2 },
  { name: "Sarah King", id: "ID10293847", bookings: 1 },
];

// ECharts Configuration for Line Chart
const getLineChartOptions = (title) => ({
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderColor: "#ccc",
    borderWidth: 1,
    textStyle: {
      color: "#333",
    },
    formatter: function (params) {
      const [dataPoint] = params;
      return `<strong>${dataPoint.name}</strong><br/>${dataPoint.seriesName}: ${dataPoint.value}`;
    },
    axisPointer: {
      type: "line",
      lineStyle: {
        color: "#FFA500", // Match the orange line color
      },
    },
  },
  xAxis: {
    type: "category",
    data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    axisLine: { lineStyle: { color: "#ccc" } },
    axisTick: { show: false },
    axisLabel: { color: "#333" },
  },
  yAxis: {
    type: "value",
    axisLine: { lineStyle: { color: "#ccc" } },
    axisTick: { show: false },
    axisLabel: { color: "#333" },
    splitLine: { lineStyle: { color: "#eee" } },
  },
  series: [
    {
      name: title,
      data: [120, 200, 150, 80, 70, 110, 130],
      type: "line",
      smooth: true,
      lineStyle: {
        color: "#FFA500", // Orange line color
        width: 3,
      },
      itemStyle: {
        color: "#FFA500", // Blue dot color
        borderWidth: 2,
        borderColor: "#FFFFFF", // Optional: white border around blue dots for better contrast
      },
      symbolSize: 8, // Dot size
      emphasis: {
        focus: "series",
      },
    },
  ],
});

const aboutCareArray = [
  {
    value: "homeCleaning",
    title: "Home Cleaning",
  },
  {
    value: "carWashing",
    title: "Car Washing",
  },
  {
    value: "petWashing",
    title: "Pet Washing",
  },
];

const sheduleArray = [
  {
    value: "today",
    title: "Today",
  },
  {
    value: "tomorrow",
    title: "Tomorrow",
  },
  {
    value: "thisWeek",
    title: "This Week",
  },
];

const GraphHeading = ({ data, setData, arr1, arr2, heading, value }) => {
  return (
    <div
      className="
    flex flex-wrap 
    items-center 
    justify-center sm:justify-between 
    gap-1 sm:gap-0
  "
    >
      <h6
        className="
      font-dm-sans 
      text-[18px] sm:text-[16px] 
      font-bold
    "
      >
        {heading}
      </h6>

      <div className="flex flex-row gap-2">
        <select
          id="aboutCare"
          name="aboutCare"
          className="
        w-full 
        border border-gray-300 rounded-md 
        p-2 bg-white
      "
          value={data.aboutCare}
          onChange={(e) =>
            setData({
              ...data,
              aboutCare: e.target.value,
            })
          }
        >
          {arr1.map(({ value, title }, index) => (
            <option key={index * Math.random()} value={value}>
              {title}
            </option>
          ))}
        </select>

        <select
          id="shedule"
          name="shedule"
          className="
        w-full 
        border border-gray-300 rounded-md 
        p-2 bg-white
      "
          value={data.shedule}
          onChange={(e) =>
            setData({
              ...data,
              shedule: e.target.value,
            })
          }
        >
          {arr2.map(({ value, title }, index) => (
            <option key={title + Math.random() + index} value={value}>
              {title}
            </option>
          ))}
        </select>
      </div>

      <h6
        className="
      font-dm-sans 
      text-[18px] sm:text-[16px] 
      font-bold
    "
      >
        {value}
      </h6>
    </div>
  );
};

const Overview = () => {
  const dispatch = useDispatch();

  const [stats, setStats] = useState({});
  const [graph1Select, setGraph1Select] = useState({
    aboutCare: "homeCleaning",
    shedule: "today",
  });
  useEffect(() => {
    const fetchStats = async () => {
      let res = await fetchAllStats(dispatch);
      console.log(res.data);
      setStats(res.data);
    };

    fetchStats();
  }, []);

  return (
    // <div className="w-full h-full">
    //   <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full bg-white rounded-xl shadow-sm p-1 mb-6">

    //       <div className="flex items-center gap-6">
    //         <img
    //           src={AppIcons.User}
    //           className="ml-4 w-14 h-14 rounded-full"
    //         />

    //         <div >
    //           <p className="m-0 font-medium text-[11px] md:text-[14px]">
    //             All Clients
    //           </p>
    //           <p className="m-0 font-bold text-[16px] md:text-[24px]">
    //             {stats?.allCompanies}
    //           </p>
    //         </div>
    //       </div>
    //       <div className="flex items-center gap-6">
    //         <img
    //           src={AppIcons.Message}
    //           className="ml-4 w-14 h-14 rounded-full"
    //         />

    //         <div >
    //           <p className="m-0 font-medium text-[11px] md:text-[14px]">
    //             All Bookings
    //           </p>
    //           <p className="m-0 font-bold text-[16px] md:text-[24px]">
    //             {stats?.allBookings}
    //           </p>
    //         </div>
    //       </div>
    //       <div className="flex items-center gap-6">
    //         <img
    //           src={AppIcons.Offers}
    //           className="ml-4 w-14 h-14 rounded-full"
    //         />

    //         <div >
    //           <p className="m-0 font-medium text-[11px] md:text-[14px]">
    //             All Professionals
    //           </p>
    //           <p className="m-0 font-bold text-[16px] md:text-[24px]">
    //             {stats?.allProfessionals}
    //           </p>
    //         </div>
    //       </div>
    //       <div className="flex items-center gap-6">
    //         <img
    //           src={AppIcons.Services}
    //           className="ml-4 w-14 h-14 rounded-full"
    //         />

    //         <div >
    //           <p className="m-0 font-medium text-[11px] md:text-[14px]">
    //             All Services
    //           </p>
    //           <p className="m-0 font-bold text-[16px] md:text-[24px]">
    //             {stats?.allServices}
    //           </p>
    //         </div>
    //       </div>

    //   </div>
    //   <div className="flex flex-col xl:flex-row w-full gap-2 relative ">
    //     <Appointments overviewFilter />
    //     {/* <MessageBox
    //       containerClass={
    //         "no-scrollbar w-full xl:w-[calc(30%-16px)] xl:absolute mt-4 xl:right-0 xl:h-[calc(100vh-217px)] shadow-sm overflow-auto   border-red-500 "
    //       }
    //     /> */}
    //   </div>
    // </div>
    <div className="w-full h-full">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        {/* Left Section: Statistics Cards */}
        <div className="col-span-12 md:col-span-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full">
            {[
              {
                // icon: <PeopleAltIcon />,
                icon: AppIcons.User,
                label: "Total Professionals",
                value: 348,
              },
              {
                // icon: <BusinessIcon />
                icon: AppIcons.Message,
                label: "Total Services",
                value: 348,
              },
              {
                icon: AppIcons.Offers,
                // <ShoppingBagIcon />,
                label: "Total Customers",
                value: 348,
              },
              {
                icon: AppIcons.Services,
                // <AssignmentIcon />
                label: "Total Bookings",
                value: 348,
              },
            ].map((card, index) => (
              <div
                key={index}
                className={`h-full flex flex-col items-center md:items-start p-4 bg-white shadow-md rounded-lg`}
              >
                <div className="w-12 h-12  text-[#076B9E] flex items-center justify-center rounded-full mb-2">
                  {/* {card.icon} */}
                  <img
                    src={card.icon}
                    alt={""}
                    className="ml-4 w-14 h-14 rounded-full"
                  />
                </div>

                <h6 className="font-dm-sans text-lg">{card.label}</h6>
                <h4 className="font-dm-sans font-bold text-2xl">
                  {card.value}
                </h4>
              </div>
            ))}
          </div>
        </div>
        {/* Right Section: Graph 1 */}
        <div className="col-span-12 md:col-span-7">
          <div className="bg-white shadow-md p-6 h-full">
            <GraphHeading
              data={graph1Select}
              setData={setGraph1Select}
              arr1={aboutCareArray}
              arr2={sheduleArray}
              heading={"Total Revenue"}
              value={"AED 12347"}
            />
            <div className="mt-4">
              <ReactEcharts
                option={getLineChartOptions("Total Revenue")}
                style={{ height: 300 }}
              />
            </div>
          </div>
        </div>

        {/* left Section: Graph 2 */}
        <div className="col-span-12 md:col-span-7">
          <div className="bg-white shadow-md p-6 h-full">
            <GraphHeading
              data={graph1Select}
              setData={setGraph1Select}
              arr1={aboutCareArray}
              arr2={sheduleArray}
              heading={"Total Revenue"}
              value={"AED 12347"}
            />
            <div className="mt-4">
              <DoubleLineChart />
            </div>
          </div>
        </div>

        {/* Left Section: Top 5 Customers */}
        <div className="col-span-12 md:col-span-5 md:mt-0">
          <div className="bg-white shadow-md p-6 h-full">
            <h5 className="font-dm-sans font-bold text-lg mb-4">
              Top 5 Customers
            </h5>
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm sm:text-base font-dm-sans">Customer Name</p>
              <p className="text-sm sm:text-base font-dm-sans">
                No. of Bookings
              </p>
            </div>
            {customers.map((customer, index) => (
              <div key={index} className="flex items-center my-2">
                <div className="h-9 w-9 rounded-[50%] bg-slate-300 flex justify-center items-center mr-2 text-white font-medium">
                  {customer?.name?.slice(0, 1)?.toUpperCase()}
                </div>
                <div>
                  <h6 className="font-dm-sans font-bold text-sm sm:text-base">
                    {customer.name}
                  </h6>
                  <p className="text-xs">{customer.id}</p>
                </div>
                <p className="ml-auto font-dm-sans font-bold text-sm sm:text-base">
                  {customer.bookings}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Right Section: Top 5 Service Providers */}
        <div className="col-span-12 md:col-span-7 mt-4">
          <div className="bg-white shadow-md p-6 h-full">
            <h6 className="font-semibold mb-4">Top 5 Service Providers</h6>
            <div className="flex flex-col-reverse sm:flex-row gap-4">
              <div className="flex-1">
                <div className="flex items-center mb-2">
                  <p className="text-sm sm:text-base">Rank</p>
                  <p className="ml-4 text-sm sm:text-base">Company</p>
                  <p className="ml-auto text-sm sm:text-base">Total Revenue</p>
                </div>
                {customers.map((customer, index) => (
                  <div key={index} className="flex items-center my-2">
                    <p className="text-sm sm:text-base">{index + 1}</p>
                    <div className="ml-4">
                      <p className="font-bold text-sm sm:text-base">
                        {customer.name}
                      </p>
                    </div>
                    <p className="ml-auto font-bold text-sm sm:text-base">
                      {customer.bookings}
                    </p>
                  </div>
                ))}
              </div>
              <div className="w-full sm:w-1/2">
                <ResponsiveContainer height={300}>
                  <PieChart>
                    <Pie data={pieData} dataKey="value" outerRadius={100}>
                      {pieData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            ["#FFBB28", "#FF8042", "#00C49F", "#0088FE"][index]
                          }
                        />
                      ))}
                      <LabelList
                        dataKey="value"
                        position="inside"
                        formatter={(value) => {
                          return `${value}%`; // Return the percentage with a % sign
                        }}
                        style={{
                          fill: "#2A2E33",
                          fontSize: "12px",
                          fontWeight: "bold",
                          stroke: "none",
                        }}
                      />
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        {/* Right Section: Top 5 Service Providers */}
        <div class="col-span-12 md:col-span-5 mt-0 md:mt-2">
          <div className="h-full md:h-auto">
            <div className="bg-white shadow-md p-4 h-full">
              <h5 className="font-sans font-bold text-lg">Top 5 Customers</h5>

              <div className="flex justify-between items-center my-2">
                <p className="font-sans text-sm sm:text-base">Customer Name</p>
                <p className="font-sans text-sm sm:text-base">
                  No. of Bookings
                </p>
              </div>

              {customers.map((customer, index) => (
                <div className="flex items-center space-x-2 py-2" key={index}>
                  {/* Customer Info Section */}
                  <div className="flex items-center w-5/12">
                    <div className="h-9 w-9 rounded-[50%] bg-slate-300 flex justify-center items-center mr-2 text-white font-medium">
                      {customer?.name?.slice(0, 1)?.toUpperCase()}
                    </div>
                    <div>
                      <h6 className="font-sans font-bold text-sm sm:text-base">
                        {customer.name}
                      </h6>
                      <span className="font-sans text-xs">{customer.id}</span>
                    </div>
                  </div>

                  {/* Rating Section */}
                  <div className="w-3/12">
                    <StarRating
                      // name={`rating-${index}`}
                      value={5}
                      // readOnly
                      // sx={{ fontSize: { xs: "16px", sm: "18px" } }}
                    />
                  </div>

                  {/* Bookings Section */}
                  <div className="w-4/12 flex justify-end">
                    <p className="font-sans font-bold text-sm sm:text-base">
                      {customer.bookings}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
