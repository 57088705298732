import axios from "axios";

import {
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
} from "../slices/updatePasswordSlice";

import AppConfig from "../../config"

export const updatePassword = (userId, newPassword) => async (dispatch) => {
  dispatch(updatePasswordRequest());
  try {
    // Perform API call to update password
    const response = await axios.put(
      `${AppConfig.API_BASE_URL}company/users/${userId}/update-password`,
      { password: newPassword }
    );
    if (!response.ok) {
      throw new Error("Failed to update password");
    }
    dispatch(updatePasswordSuccess());
  } catch (error) {
    dispatch(updatePasswordFailure({ error: error.message }));
  }
};
