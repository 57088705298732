import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { selectLogin } from "../../redux/slices/loginSlice";
import { login } from "../../redux/actions/authActions";

const LoginScreen = () => {
  const [email, setEmail] = useState("@halo365.com");
  const [password, setPassword] = useState("");

  const { loading, error, data } = useSelector(selectLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    console.log("Login Response:", data, error, loading);
    if (!loading && !error && data) {
      navigate("/verification-code", { state: { email } });
    }
  }, [data, error, loading, navigate, email]);

  const handleForgetPassClick = () => {
    navigate("/forgot-password");
  };

  return (
    <Grid container spacing={0} sx={{ height: "100vh" }}>
      {/* First Column: Logo */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "50%", textAlign: "right", position: "relative" }}>
          {/* Logo at the top corner */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f23ab2c1468d22a14aabb0b0349d11c5c8e83f20ef78bfce529298c7674f471a?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Logo"
            style={{
              width: "20%",
              aspectRatio: "0.5",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          />
          {/* Image in the center */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2dcc37416681c169a6af6013613cfcccf867f6e02399c212482099c6f18f3c8b?apiKey=5919a58685ca46fb99fef7a526577ea6"
            alt="Decorative"
            style={{
              width: "100%",
              aspectRatio: "1.0",
              marginTop: "40%",
              transform: "translateY(-40%)",
            }}
          />
        </Box>
      </Grid>

      {/* Second Column: Form */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "#E6F2F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 9 }}>{/* Illustration */}</Box>
        <Box sx={{ width: "80%", textAlign: "center" }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Welcome Back
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{ width: "100%", textAlign: "center" }}
          >
            <TextField
              label="User Name"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ my: 2 }}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              sx={{ my: 2 }}
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember on this computer"
              />
            </FormGroup>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                my: 3,
                px: 6,
                py: 1.5,
                borderRadius: "50px",
                backgroundImage:
                  "linear-gradient(276deg, #F0BA47 8.16%, #F3D25C 63.6%)",
                fontWeight: "bold",
              }}
            >
              {loading ? "Logging in..." : "Log in"}
            </Button>
            <Button onClick={handleForgetPassClick} sx={{ my: 2 }}>
              Forgot Password?
            </Button>
          </Box>
          {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
