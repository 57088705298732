
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import StaffListCard from "../../components/StaffListCard";
import UserProfileBoard from "../../components/UserProfileBoard";
import { fetchStaffList } from "../../redux/actions/staffListActions";
import { selectStaffList } from "../../redux/slices/staffListSlicer"
import { fetchProfessions } from "../../redux/actions/professionsActions";
import { selectProfessions } from "../../redux/slices/professionsSlice";
import AppConfig from "../../config"
import {AppContext} from '../../config/AppContextProvider'

const StaffList = () => {
  const list = [
    {
      id: 1,
      name: "Ahsen Shamil",
      totalCustomers: 43,
      status: "Completed",
      role: "Hair Stylist",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        // ... work history entries for Aisha Ahmed
      ],
    },
    {
      id: 2,
      name: "Aisha Ahmed",
      totalCustomers: 43,
      role: "Hair Stylist",
      status: "In Work",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        {
          status: "Completed",
          date: "28 Jan / 10.30AM",
          user: "Aisha Ahmed",
          service: "HairCut",
        },
        // ... work history entries for Aisha Ahmed
      ],
    },
    {
      id: 3,
      name: "Ahsen Shamil",
      totalCustomers: 43,
      status: "Completed",
      role: "Hair Stylist",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Ahsen Shamil",
          service: "HairCut",
        },
        // ... other work history entries
      ],
    },
    {
      id: 4,
      name: "Ahsen Shamil",
      totalCustomers: 43,
      status: "Completed",
      role: "Hair Stylist",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Ahsen Shamil",
          service: "HairCut",
        },
        // ... other work history entries
      ],
    },
    {
      id: 5,
      name: "Ahsen Shamil",
      totalCustomers: 43,
      status: "Completed",
      role: "Hair Stylist",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Ahsen Shamil",
          service: "HairCut",
        },
        // ... other work history entries
      ],
    },
    {
      id: 6,
      name: "Ahsen Shamil",
      totalCustomers: 43,
      status: "Completed",
      role: "Hair Stylist",
      phone: "+971 55 123 1425",
      email: "Sample@email.com",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e6fc1e149105b5ab2c203e1c701e514e7b50a88ce061c7aa3bcfec561e2ada7d?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      workHistory: [
        {
          status: "In work",
          date: "28 Jan / 10.30AM",
          user: "Ahsen Shamil",
          service: "HairCut",
        },
        // ... other work history entries
      ],
    },
    // ... other staff entries
  ];
  const { companyId } = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { staffList } = useSelector(selectStaffList)
  const { professions } = useSelector(selectProfessions);

  useEffect(() => {
    if (professions.length === 0) {
      dispatch(fetchProfessions())
      console.log('getting professions from staffList')
    }

    if (staffList.length === 0)
      dispatch(fetchStaffList(companyId));
  }, [dispatch]);
  useEffect(() => {

    setSelectedUser(staffList[0])
  }, [staffList]);
  const [selectedUser, setSelectedUser] = useState({});
  const [image, setImage] = useState('');
  const handleProfileClick = (user, img) => {

    setImage(img)
    setSelectedUser(user);
  };

  const handleEditClick = (staffItem) => {
    navigate(`/staff-list/edit/${staffItem._id}`, { state: { staff: staffItem, professionList: professions.filter(x=>x.isActive===true) } });
  };
  const handleHistoryClick = (status, date, user, service) => {
    console.log(
      `History item clicked: ${status}, ${date}, ${user}, ${service}`
    );
  };
  const handleAddProfessionClick = () => {

  }

  return (
    <div className="p-1">
      {/* <header className="flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
                <h2 className="flex-auto my-auto text-3xl tracking-[2.4px]">
                    
                </h2>
                <button
                    className="justify-center px-3 py-2 mb-4 text-base text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
                    onClick={handleAddProfessionClick}
                >
                    Add a Professional
                </button>
            </header> */}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2 gap-3 p-3 bg-white w-full xl:w-[calc(100%-330px)] rounded-[10px] shadow-sm">
        {staffList.map((item, index) => (
          <div key={index} className="col-span-1">
            <StaffListCard
              listItem={item}
              isSelected={selectedUser && selectedUser._id === item.id}
              onProfileClick={(img, f) => handleProfileClick(item, img)}
            />
          </div>
        ))}
      </div>
      <div className="hidden xl:block xl:col-span-3">
        {selectedUser &&

          <UserProfileBoard
            image={image}
            selectedUser={selectedUser}
            onProfileClick={() => handleProfileClick(null)} // Clear selection on profile click
            onHistoryClick={handleHistoryClick}
          />
        }

      </div>
    </div>
  );
};

export default StaffList;
