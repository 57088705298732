/* eslint-disable react/prop-types */

import { useParams } from "react-router-dom";

import { MdOutlineExpandMore } from "react-icons/md";
import { useState } from "react";
const TableGrid = ({ heading, value, arrayStatus = false, arr = [] }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <span className="text-gray-600 font-medium w-1/2">{heading}</span>
      <span className="text-right text-black font-bold w-1/2">
        {arrayStatus ? (
          <ul className="mt-0 pl-4 list-none">
            {arr.map((val, i) => (
              <li key={val + i + Math.random()}>{val}</li>
            ))}
          </ul>
        ) : (
          value
        )}
      </span>
    </div>
  );
};

const Accordion = ({ children, expanded, toggle }) => {
  return (
    <div
      className={`border rounded-lg bg-blue-50 ${expanded ? "shadow-sm" : ""}`}
    >
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggle}
      >
        <h6 className="text-lg font-bold text-black">Sub Bookings</h6>
        <span className={`${expanded ? "transform rotate-180" : ""}`}>
          <MdOutlineExpandMore />
        </span>
      </div>
      {expanded && <div className="p-4 border-t">{children}</div>}
    </div>
  );
};

function TransactionDetail() {
  const { id } = useParams();
  const [isExpanded, setIsExpanded] = useState(true); // State for controlling the accordion

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="w-full h-full">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-md p-1 md:p-2">
        {/* Booking Details Header Section */}
        <div className="rounded-lg">
          <div className="p-4">
            {/* Booking Info Grid */}
            <div className="grid grid-cols-1 gap-2">
              <div className="w-full flex justify-between items-center">
                <h2 className="text-black font-bold text-lg w-1/2">
                  Booking Details
                </h2>
                <span className="text-gray-600 text-right font-normal text-lg w-1/2">
                  Status – <span className="text-black font-bold">Pending</span>
                </span>
              </div>

              <TableGrid
                heading={"Address"}
                value={
                  "Fortune Tower - 801 Cluster C - Jumeirah Lake Towers - Dubai - United Arab Emirates"
                }
              />

              <TableGrid heading={"Frequency"} value={"Weekly"} />
              <TableGrid heading={"Duration"} value={"2 hours"} />
              <TableGrid
                heading={"Date & Start Time"}
                value={"13 Jun 2024, 08:00–08:30"}
              />

              <TableGrid heading={"Material"} value={"No"} />
              <TableGrid
                heading={"Instructions"}
                value={"Lorem ipsum dummy text"}
              />
            </div>
          </div>
        </div>
        <Accordion expanded={isExpanded} toggle={toggleAccordion}>
          {/* First Sub-Booking */}
          <div className="mb-4">
            <span className="text-gray-500 mb-2 block">
              Booking ID: <span className="font-bold text-black">123456</span>
            </span>
            <div className="grid grid-cols-1 gap-2">
              <TableGrid heading={"Status:"} value={"Pending"} />
              <TableGrid heading={"Professional Name:"} value={"Mohamed"} />
              <TableGrid
                heading={"Service:"}
                value={""}
                arrayStatus={true}
                arr={["2x Home Cleaning", "1x Sofa Cleaning"]}
              />
              <TableGrid heading={"Amount:"} value={"AED 30"} />
            </div>
            <div className="border-t border-gray-300 my-2"></div>{" "}
            {/* Divider */}
          </div>

          {/* Second Sub-Booking (similar structure) */}
          <div className="mb-4">
            <span className="text-gray-500 mb-2 block">
              Booking ID: <span className="font-bold text-black">123456</span>
            </span>
            <div className="grid grid-cols-1 gap-2">
              <TableGrid heading={"Status:"} value={"Pending"} />
              <TableGrid heading={"Professional Name:"} value={"Mohamed"} />
              <TableGrid
                heading={"Service:"}
                value={""}
                arrayStatus={true}
                arr={["2x Home Cleaning", "1x Sofa Cleaning"]}
              />
              <TableGrid heading={"Amount:"} value={"AED 30"} />
            </div>
          </div>
        </Accordion>

        {/* Payment Summary Section */}
        <div className="rounded-lg mt-4">
          <div className="p-4">
            <h3 className="text-black font-bold text-lg">Payment Summary</h3>
            <div className="grid grid-cols-1 gap-1 mt-2">
              <TableGrid heading={"Total Booking Amount"} value={"AED 63.00"} />
              <TableGrid heading={"Sub booking amount"} value={"AED 16.30"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetail;
