import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  professions: [],
  loading: false,
  error: null,
};

const professionsSlice = createSlice({
  name: "professions",
  initialState,
  reducers: {
    ProfessionsAPIStart(state) {
      state.loading = true;
      state.error = null;
    },
    ProfessionsAPIFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getProfessionsSuccess(state, action) {
      state.loading = false;
      state.professions = action.payload.data;
    },
    
  },
});

export const {
  ProfessionsAPIStart,
  getProfessionsSuccess,
  ProfessionsAPIFailure,
} = professionsSlice.actions;

export const selectProfessions = (state) => state.professions;

export default professionsSlice.reducer;
