import {fetchAllEntities, createEntity,updateEntity, deleteEntity, fetchEntity} from "./baseHalo.api"
import AppConfig from '../config/index'

export const fetchCompanyUserAddressAPI = async (userId,addrId,dispatch) => {
  try {
    const response = await fetchEntity(AppConfig.endpoints.COMPANY_USER_ADDRESS.GET(userId), addrId,dispatch);
    
    return response;
  } catch (error) {
    throw new Error("Failed to fetch Staff List: " + error.message);
  }
};

export const createCompanyUserAddressAPI = async (addrData, userid, dispatch) => {
  try {
    const response = await createEntity(AppConfig.endpoints.COMPANY_USER_ADDRESS.CREATE(userid),addrData ,dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to create Staff: " + error.message);
  }
};

export const  updateCompanyUserAddressAPI = async (addrData,userId ,dispatch) => {
  try {
    const response = await updateEntity(AppConfig.endpoints.COMPANY_USER_ADDRESS.UPDATE(addrData._id, userId),userId,addrData ,dispatch);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const createCompanyAddressAPI = async (addrData, companyId, dispatch) => {
  try {
    const response = await createEntity(AppConfig.endpoints.COMPANY_ADDRESS.CREATE(companyId),addrData ,dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to create Staff: " + error.message);
  }
};

export const updateCompanyAddressAPI = async (addrData,companyId ,dispatch) => {
  try {
    const response = await updateEntity(AppConfig.endpoints.COMPANY_ADDRESS.UPDATE(addrData._id, companyId),companyId,addrData ,dispatch);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};


