// subCategoriesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subCategory: {},
  subCategories: [],
  allSubCategories : [],
  loading: false,
  creating: false,
  updating: false,
  error: null,
  success: false,
};

const subCategoriesSlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {
    filterSubCats(state,action) {
        state.subCategories=state.allSubCategories.filter(x=>x.category == action.payload)
      },
    fetchSubCategoriesStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    
    fetchSubCategoriesSuccess(state, action) {
      state.subCategories = action.payload.data;
      state.allSubCategories = action.payload.data
      state.loading = false;
      state.success = true;
    },
    fetchSubCategoriesFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.success = false;
    },
    createSubCategoryStart(state) {
      state.creating = true;
      state.error = null;
      state.success = false;
    },
    createSubCategorySuccess(state, action) {
      state.subCategory = action.payload.data;
      state.creating = false;
      state.success = true;
    },
    createSubCategoryFailure(state, action) {
      state.creating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    updateSubCategoryStart(state) {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateSubCategorySuccess(state, action) {
      state.subCategory = action.payload.data;
      state.updating = false;
      state.success = true;
    },
    updateSubCategoryFailure(state, action) {
      state.updating = false;
      state.error = action.payload.error;
      state.success = false;
    },
  },
});

export const {
  fetchSubCategoriesStart,
  fetchSubCategoriesSuccess,
  fetchSubCategoriesFailure,
  createSubCategoryStart,
  createSubCategorySuccess,
  createSubCategoryFailure,
  updateSubCategoryStart,
  updateSubCategorySuccess,
  updateSubCategoryFailure,
  filterSubCats
} = subCategoriesSlice.actions;

export const selectSubCategories = (state) => state.subCategories;

export default subCategoriesSlice.reducer;
