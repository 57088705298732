import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { createSubService, updateSubService } from "../../redux/actions/subServicesActions";
import { selectImageUpload } from "../../redux/slices/imageUploadSlice";
import { uploadImageAction } from "../../redux/actions/imageUploadActions";
import { fetchImage } from "../../api/imageUpload.api";
import Dropdown from 'react-bootstrap/Dropdown';



import { selectSubCategories } from "../../redux/slices/subCategorySlice";
import { selectCategories } from "../../redux/slices/categorySlice"
import { selectServices } from "../../redux/slices/servicesSlice"
import { fetchCategories } from "../../redux/actions/categoryActions";
import { fetchSubCategories } from "../../redux/actions/subCategoryAction";
import { fetchServices } from "../../redux/actions/servicesActions";

function CoverImage({ coverPhoto, onChangeCoverPicture }) {
    // const { loading, error, image } = useSelector(selectImageUpload);
    // const dispatch = useDispatch();

    const [image, setImage] = useState(null);

    useEffect(() => {
        console.log("coverPhoto", coverPhoto);
        if (coverPhoto) {
            setImage(coverPhoto)
        }
    }, [coverPhoto]);

    return (
        <div className="flex overflow-hidden relative flex-col items-end justify-end px-16 pt-20 pb-8 font-semibold tracking-widest text-center text-white min-h-[327px] bg-[#EEEEEE] max-md:px-5 max-md:max-w-full">
            {image ? (
                <img
                    loading="lazy"
                    src={image || AppImages.Placeholder}
                    alt="Cover Image"
                    className="object-cover absolute inset-0 size-full"
                />
            ) : (
                <p>Loading...</p>
            )}
            <button
                onClick={onChangeCoverPicture}
                className="absolute bottom-0 right-0 justify-center px-4 py-3 mr-4 mb-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5 max-md:mt-10"
            >
                Change Cover Picture
            </button>
        </div>
    );
}

function ProfileImage({ profilePicture, onChangeProfilePicture }) {
    // const { loading, error, image } = useSelector(selectImageUpload);
    // const dispatch = useDispatch();

    const [image, setImage] = useState(null);

    useEffect(() => {
        console.log("profilePicture", profilePicture);
        if (profilePicture) {
            setImage(profilePicture)
        }
    }, [profilePicture]);

    return (
        <div className="flex gap-3 justify-between self-start mt-16 font-semibold tracking-widest text-center text-white max-md:mt-10">
            {image ? (
                <img
                    loading="lazy"
                    src={image || AppImages.Placeholder}
                    alt="Profile Image"
                    className="shrink-0 max-w-full rounded-full border-solid aspect-square border-[7px] border-zinc-100 w-[149px]"
                />
            ) : (
                <p>Loading...</p>
            )}
            <button
                onClick={onChangeProfilePicture}
                className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
            >
                Change Picture
            </button>
        </div>
    );
}

function AddEditSubService() {
    const location = useLocation();
    const subService = location.state;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, error, imageURL } = useSelector(selectImageUpload);

    const [subServiceName, setSubServiceName] = useState("");
    const [description, setDescription] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [profilePicture, setProfilePicture] = useState(null);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [profilePictureURL, setProfilePictureURL] = useState(null);
    const [coverPhotoURL, setCoverPhotoURL] = useState(null);
    const [price, setPrice] = useState(0)



    
    const { categories } = useSelector(selectCategories);
    const { subCategories } = useSelector(selectSubCategories);
    const { services } = useSelector(selectServices);
   
    const [category, setCategory] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [service, setService] = useState(null)


    useEffect(() => {
        dispatch(fetchCategories())
        dispatch(fetchSubCategories())
        dispatch(fetchServices())
        if (subService) {
            setSubServiceName(subService.name);
            setDescription(subService.description);
            setCoverPhoto(subService.image);
            setProfilePicture(subService.icon);
            setIsActive(subService.isActive);
            setPrice(subService.price)
            setService(services.filter(x=>x.id == subService.service)[0])
            fetchImage(subService.image)
                .then((img) => setCoverPhoto(img))
            fetchImage(subService.icon)
                .then((img) => setProfilePicture(img))
        }
    }, [subService]);

    // useEffect(() => {
    //   console.log("imageURL", imageURL);
    //   if (imageURL) {
    //     // Check the subfolder to determine which image URL it is
    //     if (imageURL.includes("cover")) {
    //       // Update cover photo URL state
    //       setCoverPhoto(imageURL);
    //     } else if (imageURL.includes("icon")) {
    //       // Update profile picture URL state
    //       setProfilePicture(imageURL);
    //     }
    //   }
    // }, [imageURL]);

    const handleSubServiceNameChange = (e) => {
        setSubServiceName(e.target.value);
    };

    const handlePriceChange = (e)=>{
        setPrice(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    // Function to handle toggle of the switch
    const handleActiveToggle = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };

    const handleChangeCoverPicture = () => {
        pickFile([FileTypes.IMAGE], async (result) => {
            dispatch(uploadImageAction("services", "cover", result,
                (path) => {
                    setCoverPhotoURL(path)
                    setCoverPhoto(URL.createObjectURL(result))
                }
            ))
        });
    };

    const handleChangeProfilePicture = () => {
        pickFile([FileTypes.IMAGE], async (result) => {
            dispatch(uploadImageAction("services", "icon", result,
                (path) => {
                    setProfilePictureURL(path)
                    setProfilePicture(URL.createObjectURL(result))
                }
            ))
        });
    };

    const handleSave = () => {


        const updatedSubServiceData = {};

        // Check for each field if it's been updated, and if so, add it to the updatedSubServiceData
        if (subService?.name !== subServiceName) {
            updatedSubServiceData.name = subServiceName;
        }

        if (!subServiceName || !description || !coverPhoto || !profilePicture || !service || !price) {
            alert("Please fill in all fields.");
            return;
        }

        updatedSubServiceData.name = subServiceName;
        updatedSubServiceData.isActive = isActive;
        updatedSubServiceData.description = description;
        updatedSubServiceData.image = coverPhotoURL;
        updatedSubServiceData.icon = profilePictureURL;
        updatedSubServiceData.price = price

        console.log("Save me", updatedSubServiceData);

        if (subService) {
            // Update existing subService
            updatedSubServiceData.id = subService.id;
            dispatch(updateSubService(updatedSubServiceData));
        } else {
            // Create new subService
            dispatch(createSubService({
                name: subServiceName,
                isActive: isActive,
                description: description,
                image: coverPhotoURL,
                icon: profilePictureURL,
                price: price,
                serviceId: service.id
            }));
        }
        navigate(-1)
    };
    const categorySelected = (obj) => {
        setCategory(obj)
        setSubCategory(null)
        setService(null)
    }
    const subCategorySelected = (obj) => {
        setSubCategory(obj)
        setService(null)
        //dispatch(filterServicesAction(obj.id))
        // subCategories = subCategories.filter(x=>x.category == obj.id)
        console.log(subCategories)
    }
    const serviceSelected = (obj) =>{
        setService(obj)
    }
    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <CoverImage
                coverPhoto={coverPhoto}
                onChangeCoverPicture={handleChangeCoverPicture}
            />
            <ProfileImage
                profilePicture={profilePicture}
                onChangeProfilePicture={handleChangeProfilePicture}
            />
            <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="SubService Name"
                    value={subServiceName}
                    onChange={handleSubServiceNameChange}
                    placeholder="Enter subService name"
                />
                <FieldInput
                    label="Active/Inactive"
                    value={isActive}
                    onChange={handleActiveToggle}
                    type="switch"
                    placeholder="Active"
                />
            </div>
            <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Category"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Category
                    </label>
                    <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
                        <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
                            <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                                {category == null ? 'Select Category' : category.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="btn-dropdown-list-halo">
                                {categories.map((category, index) => (
                                    <Dropdown.Item onClick={
                                        () => {
                                            categorySelected(category);
                                        }

                                    } >{category.name}</Dropdown.Item>

                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Category"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Sub Category
                    </label>
                    <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
                        <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
                            <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                                {subCategory == null ? 'Select Sub Category' : subCategory.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="btn-dropdown-list-halo">
                                {subCategories.filter(x => x.category == category?.id).map((subCategory, index) => (
                                    <Dropdown.Item onClick={
                                        () => {
                                            subCategorySelected(subCategory);
                                        }

                                    } >{subCategory.name}</Dropdown.Item>

                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Service"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Service
                    </label>
                    <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
                        <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
                            <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                                {service == null ? 'Select Service' : service.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="btn-dropdown-list-halo">
                                {services.filter(x => x.subCategory == subCategory?.id).map((service, index) => (
                                    <Dropdown.Item onClick={
                                        () => {
                                            serviceSelected(service);
                                        }

                                    } >{service.name}</Dropdown.Item>

                                ))}

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <FieldInput
                    label="Price"
                    value={price}
                    onChange={handlePriceChange}
                    type="number"
                    placeholder="Active"
                />

            </div>

            <FieldInput
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Enter description"
                type="textarea"
            />

            <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                <button
                    onClick={handleSave}
                    className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
                >
                    {subService ? "Save" : "Add SubService"}
                </button>
                <button
                    onClick={handleCancel}
                    className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
                >
                    Cancel
                </button>
            </div>
        </section>
    );
}

export default AddEditSubService;
