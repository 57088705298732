import * as React from "react";
import { AppIcons } from "../assets";
import "../styles/payment-info.style.css";

/*function CustomerInfo({ customer, onStatusClick }) {
  return (
    <>
      <div className="flex gap-1 justify-between font-bold tracking-widest">
        <img
          loading="lazy"
          src={customer.image}
          alt={`Profile picture of ${customer.name}`}
          className="shrink-0  w-[62px]"
        />
        <div className="flex flex-col my-auto text-black font-metropolis font-bold text-base">
          <div>{customer.name}</div> <div className="mt-1">{customer.id}</div>
        </div>
      </div>
      <div className="flex gap-5 items-center self-start mt-4 font-semibold max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto tracking-widest text-black font-metropolis font-medium text-base">
          {customer.service}
        </div>
        <div className="text-black font-metropolis font-medium text-base">
          {customer.date}
        </div>
        <div className="text-black font-metropolis font-medium text-base">
          {customer.amount}
        </div>
        <button
          type="button"
          className={` px-8 py-2 tracking-widest text-center whitespace-nowrap rounded-md max-md:px-5 ${
            customer.status === "Paid"
              ? "items-center text-white bg-green-400"
              : customer.status === "Cancelled"
              ? "text-white bg-rose-500"
              : customer.status === "Pending"
              ? "bg-green-400"
              : ""
          }`}
          onClick={() => onStatusClick(customer.id)}
        >
          {customer.status}
        </button>
      </div>
    </>
  );
}
const PaymentInformation = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [numEntries, setNumEntries] = React.useState(20);
  const customers = [
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Cancelled",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
  ];
  const filteredCustomers = customers.filter(
    (customer) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.id.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const displayedCustomers = filteredCustomers.slice(0, numEntries);
  const handleStatusClick = (customerId) => {
    console.log(`Status clicked for customer ID: ${customerId}`); // Add your custom logic here
  };
  return (
    <div className="flex flex-col px-10 pt-7 bg-white rounded-2xl shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="inline-flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <div className="inline-flex gap-2 items-center font-semibold tracking-widest text-black">
          <div className="grow">Show</div>
          <div className="flex justify-between px-2 rounded-lg">
            <select
              value={numEntries}
              onChange={(e) => setNumEntries(parseInt(e.target.value))}
              className="border border-gray-300 rounded-md px-4 py-2 text-center bg-blue-100"
            >
              <option value={20}>20</option>
              <option value={30}>50</option>
              <option value={40}>100</option>
            </select>
          </div>
          <div>Entries</div>
        </div>
        <div className="inline-flex gap-3.5 w-80 h-14 flex-shrink-0 border border-gray-300 rounded-md px-4 py-2 text-center">
          <img
            loading="lazy"
            src={AppIcons.Search}
            alt=""
            className="shrink-0 aspect-square w-[18px]"
          />
          <input
            type="search"
            id="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-auto my-auto bg-transparent border-none focus:outline-none"
          />
        </div>
      </div>
      <section className="flex flex-col pt-11 pb-6 mt-8 text-black bg-white rounded-xl border border-solid border-zinc-300 max-md:max-w-full">
        <header className="flex gap-5 mx-11 tracking-widest max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full headings">
          <div>Customer Name</div>
          <div>Services</div>
          <div>Date</div>
          <div>Amount</div>
          <div>Payment Status</div>
        </header>
        <hr className="shrink-0 mt-8 h-px bg-gray-200 border border-gray-200 border-solid max-md:max-w-full" />
        {displayedCustomers.map((customer, index) => (
          <React.Fragment key={index}>
            <div className="flex gap-5 justify-between mr-6 ml-6 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
              <CustomerInfo
                customer={customer}
                onStatusClick={handleStatusClick}
              />
            </div>
            {index !== displayedCustomers.length - 1 && (
              <hr className="shrink-0 mt-8 h-px bg-gray-200 border border-gray-200 border-solid max-md:max-w-full" />
            )}
          </React.Fragment>
        ))}
      </section>
    </div>
  );
};

export default PaymentInformation;*/

function CustomerInfo({ customer, onStatusClick }) {
  return (
    <tr className="border-b border-gray-200">
      <td className="py-4 px-6">
        <div className="flex gap-1 font-bold tracking-widest">
          <img
            loading="lazy"
            src={customer.image}
            alt={`Profile picture of ${customer.name}`}
            className="shrink-0 w-[62px]"
          />
          <div className="flex flex-col my-auto text-black font-metropolis font-bold text-base">
            <div>{customer.name}</div> <div className="mt-1">{customer.id}</div>
          </div>
        </div>
      </td>
      <td className="tracking-widest text-black font-metropolis font-medium text-base py-4 px-6">
        {customer.service}
      </td>
      <td className="text-black font-metropolis font-medium text-base py-4 px-6">
        {customer.date}
      </td>
      <td className="text-black font-metropolis font-medium text-base py-4 px-6">
        {customer.amount}
      </td>
      <td className="py-4 px-6">
        <button
          type="button"
          className={`px-8 py-2 tracking-widest text-center whitespace-nowrap rounded-md max-md:px-5 ${
            customer.status === "Paid"
              ? "items-center text-white bg-green-400"
              : customer.status === "Cancelled"
              ? "text-white bg-rose-500"
              : customer.status === "Pending"
              ? "bg-[#E6F2F6] text-black"
              : ""
          }`}
          onClick={() => onStatusClick(customer.id)}
        >
          {customer.status}
        </button>
      </td>
    </tr>
  );
}

const PaymentInformation = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [numEntries, setNumEntries] = React.useState(20);
  const customers = [
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Paid",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Cancelled",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
    {
      name: "Ahsen Shamil",
      id: "ID232393",
      image: AppIcons.User,
      service: "Hair Colour & Bleach - Hair",
      date: "20 Jan 2022",
      amount: "AED 120",
      status: "Pending",
    },
  ]
  const filteredCustomers = customers.filter(
    (customer) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.id.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const displayedCustomers = filteredCustomers.slice(0, numEntries);
  const handleStatusClick = (customerId) => {
    console.log(`Status clicked for customer ID: ${customerId}`); // Add your custom logic here
  };
  return (
    <div className="flex flex-col px-10 pt-7 bg-white rounded-xl shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="inline-flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <div className="inline-flex gap-2 items-center font-semibold tracking-widest text-black">
          <div className="grow">Show</div>
          <div className="flex justify-between px-2 rounded-lg">
            <select
              value={numEntries}
              onChange={(e) => setNumEntries(parseInt(e.target.value))}
              className="border border-gray-300 rounded-md px-4 py-2 text-center bg-blue-100"
            >
              <option value={20}>20</option>
              <option value={30}>50</option>
              <option value={40}>100</option>
            </select>
          </div>
          <div>Entries</div>
        </div>
        <div className="inline-flex gap-3.5 w-80 h-14 flex-shrink-0 border border-gray-300 rounded-md px-4 py-2 text-center">
          <img
            loading="lazy"
            src={AppIcons.Search}
            alt=""
            className="shrink-0 aspect-square w-[18px]"
          />
          <input
            type="search"
            id="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-auto my-auto bg-transparent border-none focus:outline-none"
          />
        </div>
      </div>
      <section className="mt-8 bg-white rounded-lg border border-solid border-zinc-300 max-md:max-w-full">
        <table className="w-full text-black font-metropolis font-medium text-base">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="tracking-widest text-black font-metropolis font-bold text-base py-6 px-6">Customer Name</th>
              <th className="tracking-widest text-black font-metropolis font-bold text-base py-6 px-6">Services</th>
              <th className="tracking-widest text-black font-metropolis font-bold text-base py-6 px-6">Date</th>
              <th className="tracking-widest text-black font-metropolis font-bold text-base py-6 px-6">Amount</th>
              <th className="tracking-widest text-black font-metropolis font-bold text-base py-6 px-6">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {displayedCustomers.map((customer, index) => (
              <CustomerInfo
                key={index}
                customer={customer}
                onStatusClick={handleStatusClick}
              />
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default PaymentInformation;

