import React, { useState } from "react";
import moment from "moment";
import { AppIcons } from "../assets";

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [events] = useState([
    {
      userName: "Ahsan Shamil",
      jobTitle: "Hair Stylist",
      profileImage:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c8432dd814bc1230366f48ff608abe1981f67b39cdd101d33856264a0acfff9c?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      appointments: [
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "01:00 AM",
          to: "01:30 AM",
        },
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "09:30 AM",
          to: "10:00 AM",
        },
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "10:00 AM",
          to: "10:30 AM",
        },
      ],
      appointmentsDate: "Sep 19 2022",
    },
    {
      userName: "Ahsan Shamil",
      profileImage:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c8432dd814bc1230366f48ff608abe1981f67b39cdd101d33856264a0acfff9c?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      jobTitle: "Hair Stylist",
      appointments: [
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "01:00 PM",
          to: "01:30 PM",
        },
      ],
      appointmentsDate: "Sep 19 2022",
    },
    {
      userName: "Ahsan Shamil",
      jobTitle: "Hair Stylist",
      profileImage:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c8432dd814bc1230366f48ff608abe1981f67b39cdd101d33856264a0acfff9c?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      appointments: [
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "01:00 PM",
          to: "01:30 PM",
        },
      ],
      appointmentsDate: "Sep 19 2022",
    },
    {
      userName: "Ahsan Shamil",
      jobTitle: "Hair Stylist",
      profileImage:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c8432dd814bc1230366f48ff608abe1981f67b39cdd101d33856264a0acfff9c?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      appointments: [
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "01:00 PM",
          to: "01:30 PM",
        },
        {
          title: "Ahsan Shamil",
          service: "Hair Cut/Beard Trim",
          from: "02:00 AM",
          to: "02:30 AM",
        },
      ],
      appointmentsDate: "Sep 19 2022",
    },
  ]);

  const [activeEvent, setActiveEvent] = useState("");

  const generateTimeSlots = () => {
    const startTime = currentDate.clone().startOf("day").hour(0);

    const timeSlots = [];
    for (let i = 0; i < 24; i++) {
      const time = startTime.clone().add(i, "hours");
      const formattedTime = time.format("hh:mm A");
      timeSlots.push(formattedTime);
    }

    return timeSlots;
  };

  const handleNextDay = () => {
    const nextDay = currentDate.clone().add(1, "day");
    setCurrentDate(nextDay);
  };

  const handlePrevDay = () => {
    const prevDay = currentDate.clone().subtract(1, "day");
    setCurrentDate(prevDay);
  };

  return (
    <div className="bg-white pt-[26px] rounded-xl shadow-sm">
      <div className="flex items-center  bg-white w-[calc(100%-40px)] xl:w-[calc(100%-330px)] z-10 rounded-t-xl">
        <button
          onClick={handlePrevDay}
          className="h-[60px] w-[60px] ml-[25px] rounded-[50%] flex items-center justify-center bg-[#E6F2F6] mr-4"
        >
          <img src={AppIcons.ChevronLeft} alt="Chevron Left" className="h-3" />
        </button>
        <button
          onClick={handleNextDay}
          className="h-[60px] w-[60px] rounded-[50%] flex items-center justify-center bg-[#E6F2F6] mr-8"
        >
          <img
            src={AppIcons.ChevronLeft}
            alt="Chevron Left"
            className="h-3 transform rotate-180"
          />
        </button>
        <p className="text-[24px] font-bold m-0 ">
          {currentDate.format("MMM DD YYYY")}
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span className="font-normal">
            {moment().format("MMM DD YYYY") ===
              currentDate.format("MMM DD YYYY") && "Today"}
          </span>
        </p>
      </div>
      <div className="flex overflow-auto mt-4">
        <div className="w-[193px]">
          <div className="flex items-center justify-center h-[115px] border-[#DFDFDF] border-t border-b w-[193px]">
            <h3 className=" text-[24px] font-bold align-center ">All Day</h3>
          </div>
          {generateTimeSlots().map((timeSlot, index) => (
            <div
              key={index}
              className="flex  justify-center  h-36 text-[16px] font-medium"
            >
              {timeSlot}
            </div>
          ))}
        </div>
        {events.map((user, userIndex) => {
          return (
            <div className="border-[#DFDFDF] border-l ">
              <div
                key={userIndex}
                className="flex items-center gap-[22px] w-[100%] min-w-[307px] px-[26px] h-[115px] border-[#DFDFDF] border-t"
              >
                <img
                  src={user.profileImage}
                  alt={user.userName}
                  className="h-16 w-16 object-cover"
                />
                <div>
                  <h4 className="text-[16px] m-0 font-bold">{user.userName}</h4>
                  <p className="text-[16px] m-0 mt-1 font-medium">
                    {user.jobTitle}
                  </p>
                </div>
              </div>
              {generateTimeSlots().map((timeSlot, index) => (
                <div
                  key={index}
                  className="flex  justify-center  h-36 text-[16px] font-medium min-w-fit w-full border-[#DFDFDF] border-t "
                >
                  {user.appointments.map((app, index) => {
                    if (app.from === timeSlot) {
                      const isActive = userIndex + "-" + index === activeEvent;
                      let gradient = {};
                      let eventClass =
                        "mx-4 my-3 flex items-center gap-6  rounded-[19px] bg-[#E6F2F6] pl-4 pr-3 cursor-pointer relative";
                      if (isActive) {
                        eventClass += " text-[#fff]";
                        gradient = {
                          background: `linear-gradient(270deg,#076B9E 8%, #01A0AA 100%)`,
                        };
                      }
                      return (
                        <div
                          key={index}
                          className={eventClass}
                          style={{
                            ...gradient,
                          }}
                          onClick={() => {
                            if (`${userIndex}-${index}` === activeEvent) {
                              setActiveEvent("");
                            } else {
                              setActiveEvent(`${userIndex}-${index}`);
                            }
                          }}
                        >
                          <div
                            className={
                              "bg-[#fff] h-[49px] w-[49px] rounded-[50%] flex items-center justify-center pl-"
                            }
                          >
                            <img
                              src={AppIcons.Calendar}
                              alt="Calendar"
                              className={"h-6"}
                            />
                          </div>
                          <div>
                            <h3 className="text-[14px] font-bold m-0">
                              {app.title}
                            </h3>
                            <h4 className="text-[14px] font-medium my-[7px] mx-0">
                              {app.service}
                            </h4>
                            <p className="text-[14px] font-medium m-0">
                              <span
                                className={
                                  moment().format("MMM DD YYYY") !==
                                    currentDate.format("MMM DD YYYY") &&
                                  "hidden"
                                }
                              >
                                {"Today - "}
                              </span>
                              {app.from} - {app.to}
                              <span
                                className={
                                  moment().format("MMM DD YYYY") !==
                                  currentDate.format("MMM DD YYYY")
                                    ? "opacity-0"
                                    : "hidden"
                                }
                              >
                                {" Today -"}
                              </span>
                            </p>
                          </div>
                          {isActive && (
                            <div className="absolute right-0 top-24 w-full bg-white text-black p-[20px] shadow-md rounded-b-xl">
                              <div className="flex items-center justify-between gap-2">
                                <div className="flex gap-2">
                                  <img
                                    src={user.profileImage}
                                    alt={user.userName}
                                    className="object-cover h-16 w-16"
                                  />
                                  <div>
                                    <h4 className="text-[16px] m-0 font-bold">
                                      {user.userName}
                                    </h4>
                                    <p className="text-[16px] m-0 mt-1 ">
                                      {user.jobTitle}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  onClick={() => setActiveEvent("")}
                                  className="rounded-[50%] h-[50px] w-[50px] flex items-center justify-center bg-[#E6F2F6]"
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.1391 25.9425C18.4155 26.7672 19.9364 27.1266 21.4463 26.9603C22.9561 26.794 24.3627 26.112 25.4295 25.0292L26.3622 24.1159C26.7711 23.697 27 23.1344 27 22.5486C27 21.9628 26.7711 21.4003 26.3622 20.9814L22.4035 17.0582C21.9888 16.65 21.4306 16.4213 20.8491 16.4213C20.2676 16.4213 19.7094 16.65 19.2946 17.0582C18.8764 17.4677 18.3147 17.697 17.7298 17.697C17.1449 17.697 16.5833 17.4677 16.165 17.0582L9.94725 10.8309C9.73983 10.6262 9.57512 10.3822 9.46269 10.1131C9.35026 9.84408 9.29237 9.55533 9.29237 9.26367C9.29237 8.97201 9.35026 8.68326 9.46269 8.41421C9.57512 8.14516 9.73983 7.90118 9.94725 7.69646C10.3548 7.28109 10.5832 6.72201 10.5832 6.13962C10.5832 5.55724 10.3548 4.99816 9.94725 4.58279L6.00933 0.638814C5.59109 0.229295 5.02942 0 4.44452 0C3.85962 0 3.29795 0.229295 2.87971 0.638814L1.96778 1.57291C0.8866 2.6414 0.205731 4.05015 0.0396515 5.56228C-0.126428 7.07441 0.232413 8.59771 1.05584 9.87602C5.34619 16.2087 10.8052 21.6619 17.1391 25.9425Z"
                                      fill="url(#paint0_linear_0_527)"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_0_527"
                                        x1="24.5551"
                                        y1="27"
                                        x2="0.323717"
                                        y2="24.3546"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stop-color="#F0BA47" />
                                        <stop
                                          offset="0.677095"
                                          stop-color="#F3D25C"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </button>
                              </div>
                              <div className="grid grid-cols-2 mt-6 pb-3 border-b border-[#DDDDDD]">
                                <div>
                                  <p className="m-0 font-medium text-[12px]">
                                    Specialist
                                  </p>
                                  <p className="m-0 font-bold text-[12px]">
                                    {user.userName}
                                  </p>
                                </div>
                                <div>
                                  <p className="m-0 font-medium text-[12px]">
                                    Service
                                  </p>
                                  <p className="m-0 font-bold text-[12px]">
                                    {app.service}
                                  </p>
                                </div>
                                <div>
                                  <p className="m-0 font-medium text-[12px]">
                                    Date
                                  </p>
                                  <p className="m-0 font-bold text-[12px]">
                                    {currentDate.format("MMM DD YYYY")}
                                  </p>
                                </div>

                                <div>
                                  <p className="m-0 font-medium text-[12px]">
                                    Time
                                  </p>
                                  <p className="m-0 font-bold text-[12px]">
                                    {app.from} - {app.to}
                                  </p>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 mt-3">
                                <div className="flex items-center gap-3 justify-center border-r border-[#DDDDDD]">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.688 12.772L6.53601 13.5194L7.22801 9.31201L15.1583 1.40938C15.287 1.27966 15.4401 1.1767 15.6087 1.10644C15.7774 1.03618 15.9583 1 16.141 1C16.3237 1 16.5046 1.03618 16.6732 1.10644C16.8419 1.1767 16.9949 1.27966 17.1236 1.40938L18.5906 2.87642C18.7204 3.00508 18.8233 3.15815 18.8936 3.32681C18.9638 3.49546 19 3.67636 19 3.85906C19 4.04176 18.9638 4.22266 18.8936 4.39131C18.8233 4.55997 18.7204 4.71304 18.5906 4.8417L10.688 12.772Z"
                                      stroke="#000001"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M16.916 13.464V17.616C16.916 17.9831 16.7702 18.3351 16.5106 18.5947C16.2511 18.8542 15.899 19 15.532 19H2.384C2.01694 19 1.66491 18.8542 1.40536 18.5947C1.14581 18.3351 1 17.9831 1 17.616V4.46804C1 4.10098 1.14581 3.74896 1.40536 3.48941C1.66491 3.22986 2.01694 3.08405 2.384 3.08405H6.53599"
                                      stroke="#000001"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Edit
                                </div>
                                <div className="flex items-center gap-3 justify-center text-red-600">
                                  <svg
                                    width="18"
                                    height="20"
                                    viewBox="0 0 18 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5H17"
                                      stroke="#000001"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M3 5H15V17C15 17.3536 14.8595 17.6928 14.6095 17.9428C14.3594 18.1929 14.0203 18.3333 13.6667 18.3333H4.33333C3.97971 18.3333 3.64057 18.1929 3.39052 17.9428C3.14048 17.6928 3 17.3536 3 17V5Z"
                                      stroke="#000001"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.6665 5V4.33333C5.6665 3.44928 6.01769 2.60143 6.64281 1.97631C7.26794 1.35119 8.11578 1 8.99984 1C9.88389 1 10.7317 1.35119 11.3569 1.97631C11.982 2.60143 12.3332 3.44928 12.3332 4.33333V5"
                                      stroke="#000001"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Delete
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                    return <div />;
                  })}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
