import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";

const DigitInput = ({ onChange, value }) => (
  <TextField
    variant="outlined"
    inputProps={{
      maxLength: 1,
      style: { textAlign: "center" },
    }}
    value={value}
    onChange={onChange}
    sx={{ width: 76, height: 76 }}
  />
);

const EmailVerificationCodeScreen = () => {
  const [digits, setDigits] = useState(["", "", "", ""]);

  const handleDigitChange = (index) => (event) => {
    const newDigits = [...digits];
    newDigits[index] = event.target.value.slice(0, 1); // Ensure only one character
    setDigits(newDigits);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/reset-password");
  };

  return (
    <Grid container spacing={0} sx={{ height: "100vh" }}>
      {/* First Column: Logo */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "50%", textAlign: "right", position: "relative" }}>
          {/* Logo at the top corner */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f23ab2c1468d22a14aabb0b0349d11c5c8e83f20ef78bfce529298c7674f471a?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Logo"
            style={{
              width: "20%",
              aspectRatio: "0.5",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          />
          {/* Image in the center */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2dcc37416681c169a6af6013613cfcccf867f6e02399c212482099c6f18f3c8b?apiKey=5919a58685ca46fb99fef7a526577ea6"
            alt="Decorative"
            style={{
              width: "100%",
              aspectRatio: "1.0",
              marginTop: "40%",
              transform: "translateY(-40%)",
            }}
          />
        </Box>
      </Grid>

      {/* Second Column: Form */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "#E6F2F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 9 }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9d704b208c1ae17918d2fc3f7394b113ad938dda6127bb4c928b699dd3dd8256?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Verification step illustration"
            style={{ width: "100%", maxWidth: "320px", height: "auto" }}
          />
        </Box>

        <Box sx={{ width: "80%", textAlign: "center" }}>
          <Typography variant="h4" gutterBottom textAlign="center">
            Email Verification
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 2, mb: 4, textAlign: "center" }}
          >
            Enter your 4 digits code that you received on your email.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            {digits.map((digit, index) => (
              <DigitInput
                key={index}
                value={digit}
                onChange={handleDigitChange(index)}
              />
            ))}
          </Box>
          <Typography variant="body1" sx={{ mt: 4, color: "orange" }}>
            00:30
          </Typography>
          <Button
            variant="contained"
            sx={{
              my: 3,
              width: "100%",
              py: 1.5,
              borderRadius: "50px",
              backgroundImage:
                "linear-gradient(276deg, #F0BA47 8.16%, #F3D25C 63.6%)",
              fontWeight: "bold",
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationCodeScreen;
