import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppIcons } from "../../assets"

function OfferCard({offer, editOfferClick}) {
  return (
    <div className="flex overflow-hidden rounded-xl relative flex-col grow justify-center text-white min-h-[262px] max-md:mt-6 max-md:w-full">
      <img
        src={offer.backgroundImage}
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <div className="flex relative flex-col pr-3.5 pl-6 mt-1 max-md:pl-5 max-md:max-w-full">
        <div className="flex gap-1 justify-between">
          <h2 className="text-2xl font-bold max-md:max-w-full">{offer.title}</h2>
          <button className="flex items-center justify-center bg-white rounded-full w-10 h-10"
          onClick={() => editOfferClick(offer)}>
            <img loading="lazy" src={AppIcons.Edit} alt="" />
          </button>
        </div>
        <p className="mt-6 text-sm leading-6 max-md:max-w-full">
          {offer.description}
        </p>
      </div>
    </div>
  );
}

function Offers() {
  const offers = [
    {
      id: 1,
      title: "Feel good, Look good and be your self",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing and typesetting industry. industry.",
      backgroundImage:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4118a8643f84b6cf9a63a48639feb225a59366c70402b781586d180222d58c54?apiKey=5919a58685ca46fb99fef7a526577ea6&",
    }
  ];
    const navigate = useNavigate();

  const handleOfferClick = (offer) => {
    console.log(`Offer ${offer.id} clicked`);
    navigate(`/offers/edit/${offer.id}`, { state: offer });
  };

  const handleAddOfferClick = () => {
    console.log("Add an Offer clicked");
    navigate("/offers/new");
  };

  return (
    <section className="flex flex-col px-7 pt-7 pb-20 bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
        <h2 className="flex-auto my-auto text-3xl text-black tracking-[2.4px] heading">
          Offers
        </h2>
        <button
          className="justify-center px-4 py-3 text-2xl text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
          onClick={handleAddOfferClick}
        >
          Add an Offer
        </button>
      </header>
      <div className="mt-8 max-md:mb-10 max-md:max-w-full">
        <div className="flex flex-wrap justify-between gap-2 max-md:flex-col max-md:gap-0">
          {offers.map((offer, index) => (
            <div key={index} className="w-[32.5%] max-md:w-full">
              <OfferCard offer={offer} editOfferClick={handleOfferClick} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Offers;
