/* eslint-disable react/prop-types */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const rows = [
  // Your existing rows here...
  {
    transactionBy: "Company Name",
    amount: "AED 87364",
    status: "Pending",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  {
    transactionBy: "Customer",
    amount: "AED 87364",
    status: "Success",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  {
    transactionBy: "Halo",
    amount: "AED 87364",
    status: "Decline",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  {
    transactionBy: "Company Name",
    amount: "AED 87364",
    status: "Pending",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  {
    transactionBy: "Customer",
    amount: "AED 87364",
    status: "Success",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  {
    transactionBy: "Halo",
    amount: "AED 87364",
    status: "Decline",
    date: "12/04/2024",
    time: "10:00 pm",
    accountNumber: "IBAN839747387428",
  },
  // Additional rows...
];

const StatusChip = ({ status }) => {
  let color;
  switch (status) {
    case "Success":
      color = "text-green-500";
      break;
    case "Pending":
      color = "text-orange-500";
      break;
    case "Decline":
      color = "text-red-500";
      break;
    default:
      color = "text-gray-500";
  }
  return <span className={`${color} font-bold`}>{status}</span>;
};

function TransactionTable() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1); // start at page 1
  const rowsPerPage = 5;
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (value) => {
    if (value < 1 || value > pageCount) return;
    setPage(value);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="py-5">
      <div className="max-w-screen-xl mx-auto">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                {[
                  "TransactionBy",
                  "Amount",
                  "Status",
                  "Date and Time",
                  "AccountNumber",
                  "Action",
                ].map((key) => (
                  <th
                    key={key}
                    className="text-left text-gray-800 font-semibold text-lg p-4 border-b"
                  >
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((row, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="p-4 border-b">{row.transactionBy}</td>
                    <td className="p-4 border-b">{row.amount}</td>
                    <td className="p-4 border-b">
                      <StatusChip status={row.status} />
                    </td>
                    <td className="p-4 border-b">{`${row.date} | ${row.time}`}</td>
                    <td className="p-4 border-b">{row.accountNumber}</td>
                    <td className="p-4 border-b">
                      <button
                        className="text-blue-600 hover:underline"
                        onClick={() => navigate(`/detail/${index + 1}`)}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex justify-center py-4">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
            >
              <IoIosArrowBack
                style={{
                  color: "#076B9E",
                }}
              />
            </button>
            {renderPageNumbers()}
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === pageCount}
              className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
            >
              <IoIosArrowForward
                style={{
                  color: "#076B9E",
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionTable;
