import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  image: null,
  imageURL: null,
  loading: false,
  error: null,
};

const imageUploadSlice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    getImageURLStart(state) {
      state.loading = true;
      state.error = null;
    },
    getImageURLSuccess(state, action) {
      state.loading = false;
      state.error = null
      state.imageURL = action.payload;
    },
    getImageURLFailure(state, action) {
      state.loading = false;
      state.imageURL = null
      state.error = action.payload;
    },
    getImageStart(state) {
      state.loading = true;
      state.error = null;
    },
    getImageSuccess(state, action) {
      state.loading = false;
      state.image = action.payload;
    },
    getImageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    
    uploadImageStart(state) {
      state.loading = true;
      state.error = null;
    },
    uploadImageSuccess(state, action) {
      state.loading = false;
      state.imageURL = action.payload.data.path;
    },
    uploadImageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearImageURL(state) {
      state.imageURL = null;
    },
  },
});

export const {
  getImageURLStart,
  getImageURLSuccess,
  getImageURLFailure,
  getImageStart,
  getImageSuccess,
  getImageFailure,
  uploadImageStart,
  uploadImageSuccess,
  uploadImageFailure,
  clearImageURL,
} = imageUploadSlice.actions;

export const selectImageUpload = (state) => state.imageUpload;

export default imageUploadSlice.reducer;
