
import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppIcons, AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { useDispatch, useSelector } from "react-redux";
import { createCompany, deleteCompany, updateCompany } from "../../redux/actions/companiesActions";
import { uploadImage, fetchImage } from "../../api/imageUpload.api";
import { createStaff, deleteStaff, updateStaff } from "../../redux/actions/staffListActions";
import { selectStaffList } from "../../redux/slices/staffListSlicer";
import userEvent from "@testing-library/user-event";
import { selectProfessions } from '../../redux/slices/professionsSlice';
import { fetchProfessions } from "../../redux/actions/professionsActions";
import DropdownSelector from "../../components/DropDownSelector";
import { createCompanyUserAddressAPI } from "../../api/companyUserAddress.api";
import 'react-phone-number-input/style.css';
import { parsePhoneNumber } from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
import { FaLocationArrow } from 'react-icons/fa';
import { loadGoogleMaps } from "../../components/loadGoogleMaps";
import { selectCompanies } from "../../redux/slices/companiesSlice";
import { fetchCompanies } from "../../redux/actions/companiesActions";
import AppConfig from "../../config"
import {AppContext} from '../../config/AppContextProvider'



const containerStyle = {
    width: "100%",
    height: "400px",
  };
  
  // Coordinates for Dubai
  const center = {
    lat: 25.276987,
    lng: 55.296249,
  };
  
  const libraries = ["places"];
  const apiKey = "AIzaSyC4xNlKGZLPp1IqLVL9BHQ6yJIYgXTptMo"; // Replace with your actual API key
function StaffImage({ staffPicture, onChangeStaffPicture }) {
    return (
        <div className="flex gap-3 justify-between self-start mt-8 font-semibold tracking-widest text-center text-white max-md:mt-5">
            <img
                loading="lazy"
                src={staffPicture || AppImages.Placeholder}
                alt={AppImages.Placeholder}
                className="shrink-0 max-w-full rounded-xl border-solid aspect-rectangle border-[7px] border-zinc-100 w-[250px]"
            />
            <button
                onClick={onChangeStaffPicture}
                className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
            >
                Change Picture
            </button>
        </div>
    );
}

function AddEditStaff() {
    const location = useLocation();
    let staff = null;
    let professionList = [];
    const routerData = location.state;
    if (routerData) {
        staff = routerData.staff;
        professionList = routerData.professionList;
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [aptNo, setAptNo] = useState("");
    const [defAddrId, setDefAddrId] = useState(0);
    const [dob, setDob] = useState("");
    const [staffPicture, setStaffPicture] = useState(null);
    const [staffPictureUrl, setStaffPictureUrl] = useState(null);
    const [imageUpdate, setImageUpdate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [company, setCompanyId] = useState(0);
    const [profession, setProfession] = useState(null);
    const [staffAddress, setStaffAddresses] = useState([]);
    const [personalEmail, setPersonalEmail] = useState('');

    const { companies } = useSelector(selectCompanies);

    const [mapLoaded, setMapLoaded] = useState(false);
    const [map, setMap] = useState(null);
    const [position, setPosition] = useState(center);
    const autocompleteRef = useRef(null);
    const markerRef = useRef(null);

    const { companyId } = useContext(AppContext);

    useEffect(() => {
        dispatch(fetchCompanies());
      }, [dispatch]);

    useEffect(() => {
        if (staff) {
            setFirstName(staff.firstName);
            setLastName(staff.lastName);
            setPhone(staff.phone.countryCode + staff.phone.phoneNumber);
            setPersonalEmail(staff.personalEmail);
            setEmail(staff.email);
            setDob(staff.dob);
            setIsActive(staff.isActive);
            setCompanyId(staff.company);
            setProfession(staff.profession);
            if (staff.profileImage) {
                fetchImage(staff.profileImage).then((img) => setStaffPictureUrl(img));
            }
            if (staff.addresses && staff.addresses.length > 0) {
                let defAddr = staff.addresses[0];
                setCity(defAddr.city);
                setPostalCode(defAddr.postalCode);
                setStreet(defAddr.street);
                setAptNo(defAddr.aptNo);
                setDefAddrId(defAddr._id);
            }
        }
    }, [staff]);


    useEffect(() => {
        loadGoogleMaps(apiKey, libraries)
          .then(() => {
            setMapLoaded(true);
          })
          .catch((error) => {
            console.error("Error loading Google Maps", error);
          });
      }, []);
    
      const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);

        // if (window.google && window.google.maps) {
        //     markerRef.current = new window.google.maps.Marker({
        //         map: mapInstance,
        //         position: center,
        //         title: "Selected Location",
        //         draggable: true,
        //     });

        //     markerRef.current.addListener("dragend", (event) => {
        //         alert('marker')
        //         setPosition({
        //             lat: event.latLng.lat(),
        //             lng: event.latLng.lng(),
        //         });
        //     });
        // }
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
        if (markerRef.current) {
            markerRef.current.setMap(null);
        }
        markerRef.current = null;
    }, []);

    const onPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const location = place.geometry.location;
            setPosition({ lat: location.lat(), lng: location.lng() });
            map.panTo(location);

            if (markerRef.current) {
                markerRef.current.setPosition(location);
            }
        }
    };
    const centerMapOnCurrentLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              const newPosition = {
                lat: latitude,
                lng: longitude,
              };
              setPosition(newPosition);
              map.panTo(newPosition);
              if (markerRef.current) {
                markerRef.current.setPosition(newPosition);
              }
            },
            (error) => {
              console.error("Error getting current location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      };
      if (!mapLoaded) {
        return <div>Loading...</div>;
      }
      const mapOptions = {
        streetViewControl: false, // Remove Pegman (Street View)
      };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePersonalEmailChange = (e) => {
        setPersonalEmail(e.target.value);
    };
    const handleDobChange = (e) => {
        setDob(e.target.value);
    };

    const handleDeleteStaffClick = () => {
        dispatch(deleteStaff(staff.id));
        navigate(-1);
    };

    const handleChangeStaffPicture = () => {
        pickFile([FileTypes.IMAGE], (result) => {
            setImageUpdate(true);
            setStaffPicture(result);
            setStaffPictureUrl(URL.createObjectURL(result));
        });
    };

    const handleActiveToggle = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };

    const validateForm = () => {
        let isInvalid = false;
        let millisec = 0;
        if (!firstName) {
            
            setTimeout(()=>{
                toast.error("First Name is required");
            },millisec)
            millisec +=1000
        
            isInvalid = true;
        }
        if (!lastName) {
            setTimeout(()=>{
                toast.error("Last Name is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!phone) {
            setTimeout(()=>{
                toast.error("Phone is required");;
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!email) {
            setTimeout(()=>{
                toast.error("Email is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!personalEmail) {
            setTimeout(()=>{
                toast.error("Personal Email is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!dob) {
            setTimeout(()=>{
                toast.error("Date of Birth is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!city) {
            setTimeout(()=>{
                toast.error("City is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!street) {
            setTimeout(()=>{
                toast.error("Street is required");
            },millisec)
            millisec +=1000
            
            
            isInvalid = true;
        }
        if (!postalCode) {
            setTimeout(()=>{
                toast.error("Postal Code is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        if (!aptNo) {
            
            setTimeout(()=>{
                toast.error("Apartment Number is required");
            },millisec)
            millisec +=1000
            isInvalid = true;
        }
        if (!profession) {
            setTimeout(()=>{
                toast.error("Profession is required");
            },millisec)
            millisec +=1000
            
            isInvalid = true;
        }
        return !isInvalid;
    };

    const handleSave = async () => {
        if (!validateForm()) return;
    
        const phoneData = {
            countryCode: '+' + parsePhoneNumber(phone).countryCallingCode,
            phoneNumber: parsePhoneNumber(phone).nationalNumber,
        };
    
        const addressData = {
            city: city,
            street: street,
            aptNo: aptNo,
            postalCode: postalCode,
            country: "UAE",
            latitude: center.lat,
            longitude: center.lng,
        };
    
        const staffData = {
            firstName: firstName,
            lastName: lastName,
            dob: dob,
            isActive: isActive,
            company: AppConfig.IS_COMP?companyId: company.companyId,
            email: email,
            personalEmail: personalEmail,
            profession: profession._id,
            phone: phoneData,
        };
    
        try {
            if (staff) {
                // Update case
                if (imageUpdate) {
                    const imageReturn = await uploadImage('staffList', 'staffImage', staffPicture);
                    staffData.profileImage = imageReturn.data.path;
                }
                await dispatch(updateStaff({ id: staff.id, ...staffData }, { _id: defAddrId, ...addressData }));
            } else {
                // New case
                if (imageUpdate) {
                    const imageReturn = await uploadImage('staffList', 'staffImage', staffPicture);
                    staffData.profileImage = imageReturn.data.path;
                }
                await dispatch(createStaff(staffData, addressData));
            }
    
            // Implement any additional logic here
            // e.g., navigate(-1) if there is no error
        } catch (error) {
            console.error("Error saving staff:", error);
            // Handle the error appropriately
        }
    
        // Implement save logic here
        console.log("Save button clicked");
    };
    
    const handleCancel = () => {
        // Implement cancel logic here console.log("Cancel button clicked");
    };

    return (
        <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">
            
            <header className="flex gap-5 justify-between font-bold max-md:flex-wrap max-md:max-w-full">
                <button
                    className="w-31 h-77 px-2 rounded-md flex-shrink-0 rounded-10 bg-blue-100"
                    onClick={() => navigate(-1)}
                >
                    <img loading="lazy" src={AppIcons.ChevronRight} alt="Profile Image" />
                </button>
                {staff ? <button
                    className="justify-center px-3 py-2 text-sm text-white rounded-lg bg-[#FC2C5A] tracking-[2px] max-md:px-5"
                    onClick={handleDeleteStaffClick}
                >
                    Delete Staff
                </button> : <div></div>}

            </header>

            <StaffImage
                staffPicture={staffPictureUrl}
                onChangeStaffPicture={handleChangeStaffPicture}
            />
            <h4 className="mt-4 text-xl">Personal Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="First Name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    placeholder="Enter Staff First Name"
                />
                <FieldInput
                    label="First Name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    placeholder="Enter Staff Last Name"
                />
                <FieldInput
                    label="Phone"
                    type="phone"
                    value={phone}
                    onChange={setPhone}
                    placeholder="Phone Number"
                />
                
                <FieldInput
                    label="Personal Email"
                    type="Email"
                    value={personalEmail}
                    onChange={handlePersonalEmailChange}
                    placeholder="Enter Email"
                />
                
                 
        
            </div>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
        
            <FieldInput
                    label="Email"
                    type="Email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter Email"
                />    
            {professionList && (
                <DropdownSelector
                    label="Profession"
                    items={professionList}
                    selectedItem={profession}
                    onSelect={setProfession}
                />
        )}
        {(!AppConfig.IS_COMP && companies) && (
                <DropdownSelector
                    label="Company"
                    items={companies}
                    selectedItem={company}
                    onSelect={setCompanyId}
                />
        )}
        </div>
        
        
                    
            
        
            <h4 className="mt-4 text-xl">Address Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="City"
                    value={city}
                    onChange={(obj) => setCity(obj.target.value)}
                    placeholder="Enter City"
                />
                <FieldInput
                    label="Postal Code"
                    type="number"
                    value={postalCode}
                    onChange={(obj) => setPostalCode(obj.target.value)}
                    placeholder="Enter Postal Code"
                />
                <FieldInput
                    label="Street"
                    value={street}
                    onChange={(obj) => setStreet(obj.target.value)}
                    placeholder="Enter Street"
                />

            </div>
            
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="Apartment Number"
                    type="number"
                    value={aptNo}
                    onChange={(obj) => setAptNo(obj.target.value)}
                    placeholder="Enter Apartment Number"
                />
                <div className="flex flex-col w-full items-end">
                    
                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                       
                    </div>
                </div>
                <div className="flex flex-col w-full items-end">
                    
                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                       
                    </div>
                </div>

            </div>
            <div style={{ position: 'relative', width: '100%' }}>
            <label
                        htmlFor="Date Of Birth"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Select Location
                    </label>
        <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Enter a location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `200px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px", // Adjust as needed
              left: "50%",
              marginLeft: "-120px",
              zIndex: 1, // Ensure it's on top of the map
            }}
          />
           {/* <FieldInput
                    label="Enter a Location"
                    type="text"
                    placeholder="Type Location"
                /> */}
        </Autocomplete>
        <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={10} onLoad={onLoad} onUnmount={onUnmount} options={mapOptions}>
         {map && (
                    <Marker
                        position={position}
                        icon={{
                            url: AppIcons.MapMarker, // Custom marker icon URL
                            scaledSize: new window.google.maps.Size(60, 60), // Adjust width and height as needed
                          }}
                        map={map}
                        title="Selected Location"
                        draggable={true}
                        onDragEnd={(event) => {
                            setPosition({
                                lat: event.latLng.lat(),
                                lng: event.latLng.lng(),
                            });
                        }}
                    />
                )}
                <img
          src={AppIcons.Send}// Replace with your custom icon URL
          alt="Current Location"
          onClick={centerMapOnCurrentLocation}
          style={{
            position: 'absolute',
            bottom: '50px',
            right: '70px',
            zIndex: 1,
            padding: '10px',
            background: '#fff',
            border: '1px solid #ccc',
            borderRadius: '50%',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            cursor: 'pointer',
            width: '50px', // Adjust icon size
            height: '50px', // Adjust icon size
          }}
        />
            </GoogleMap>
      </div>
            
            <div className="flex gap-3 items-start mt-2  tracking-widest max-md:flex-wrap max-md:max-w-full">

                <div className="flex flex-col  mt-3 text-sm  font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Date Of Birth"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Date Of Birth
                    </label>




                    <div class="">
                        <div className=" grow justify-center items-start px-4 py-6 mt-1.5  rounded-3xl border border-solid border-zinc-400  max-md:px-5 max-md:max-w-full">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <StaticDatePicker value={dayjs(dob)}
                                    onChange={(newValue) => setDob(newValue)} label="Expiry Date" />

                            </LocalizationProvider>

                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full items-end">
                    <FieldInput
                        label="Active/Inactive"
                        value={isActive}
                        onChange={handleActiveToggle}
                        type="switch"
                        placeholder="Active"
                    />
                    <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                        <button
                            onClick={handleSave}
                            className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
                        >
                            {staff ? "Save" : "Add Staff"}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div className="flex flex-col w-full items-end">
                    
                    <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                       
                    </div>
                </div>
            </div>


        </section>
    );
}

export default AddEditStaff;
