import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { fetchImage } from '../api/imageUpload.api'
import { updateStaffImage } from "../redux/slices/staffListSlicer";

const ProfileImage = ({ src, alt }) => (
  <img
    src={src}
    alt={alt}
    className="w-16 h-16 object-cover object-center rounded-full"
  />
);

const StaffListCard = ({ listItem, isSelected, onProfileClick }) => {


  const dispatch = useDispatch();
  const { firstName,lastName, profession,profileImage, isActive } = listItem;
  const [workStatus, setWorkStatus] = useState("Completed");
  const totalCustomers = 152

  const [imageUrl, setImageUrl] = useState('');
  const completeWork = () => setWorkStatus("Completed");
  const cancelWork = () => setWorkStatus("Cancelled");
  useEffect(() => {
    console.log(listItem)
    if(!listItem.image)
    {
      fetchImage(profileImage)
      .then((img) => {
        dispatch(updateStaffImage({id:listItem.id, imageBlob:img}))
        setImageUrl(img)
      })
    }
    else
    {
      setImageUrl(listItem.image)
    }
    

  }, [profileImage]);

  return (
    <div
      className={`py-[22px] px-[33px] ${
        isSelected ? "bg-[#0679A21A]" : "hover:bg-[#d8d8d846]"
      } border border-[#D8D8D8] rounded-[19px]`}
      onClick={onProfileClick.bind(this,imageUrl)}
    >
      {isSelected}
      <div className="flex gap-2">
        <ProfileImage
          src={imageUrl}
          alt="Ahsen Shamil"
        />
        <div className="flex flex-col gap-2">
          <h6 className="text-xl font-bold">{firstName + ' ' + lastName }</h6>
          <p className="text-gray-500">{profession.name}</p>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex flex-row justify-between">
          <p className="text-gray-500">Total Customers</p>
          <p>{totalCustomers}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-gray-500">Status</p>
          <p
            className={`${
              isActive == false ? "text-red-500" : "text-green-500"
            }`}
          >
            {isActive == false ? "In Active" : "Active"}
          </p>
        </div>
      </div>
      {/* <div className="flex mt-2 gap-4">
        <button
          onClick={completeWork}
          className=" py-2 px-4 bg-gradient-to-b from-[#01A0AA] to-[#076B9E] text-white font-medium rounded-[90px] h-[50px] w-[201px]"
        >
          Complete Work
        </button>
        <button onClick={cancelWork} className="ml-2 text-[#FC2C5A] font-bold">
          Cancel
        </button>
      </div> */}
    </div>
  );
};

export default StaffListCard;