import Cookies from "js-cookie";

const useAuthGuard = () => {
  let isAuthenticated = true;

  if (Cookies.get("tx") === undefined) {
    isAuthenticated = false;
  }


  return isAuthenticated;
};

export default useAuthGuard;
