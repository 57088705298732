import {
  getImageStart,
  getImageSuccess,
  getImageFailure,
  uploadImageStart,
  uploadImageSuccess,
  uploadImageFailure,
} from "../slices/imageUploadSlice";
import { fetchImage, uploadImage } from "../../api/imageUpload.api";

// Thunk to fetch image upload URL
export const fetchImageAction = (imagePath) => async (dispatch) => {
  dispatch(getImageStart());
  try {
    const imageResponse = await fetchImage(imagePath);
    dispatch(getImageSuccess(imageResponse));
  } catch (error) {
    dispatch(getImageFailure(error.message));
  }
};

// Thunk to upload image
export const uploadImageAction =
  (folder, subFolder, image, callback) => async (dispatch) => {
    dispatch(uploadImageStart());
    try {
      const uploadedImageResponse = await uploadImage(folder, subFolder, image);
      if(callback)
      callback(uploadedImageResponse.data.path)
      console.log("uploadedImageResponse", uploadedImageResponse);
      dispatch(uploadImageSuccess(uploadedImageResponse));
    } catch (error) {
      dispatch(uploadImageFailure(error.message));
    }
  };
