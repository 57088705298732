import React, { useEffect }  from "react";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CompanyListCard from "../../components/CompanyListCard";
import UserProfileBoard from "../../components/UserProfileBoard";
import { selectCompanies } from "../../redux/slices/companiesSlice";
import { fetchCompanies } from "../../redux/actions/companiesActions";



const CompanyList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchCompanies());
    }, [dispatch]);

    const { loading, error, companies } = useSelector(selectCompanies);

    const handleCompanyClick = (company) => {
        navigate(`/company-list/edit/${company.companyId}`, { state: company });
    };

    const handleHistoryClick = (status, date, user, service) => {
        console.log(
            `History item clicked: ${status}, ${date}, ${user}, ${service}`
        );
    };
    const handleAddCompanyClick = () => {
        console.log("Add an Offer clicked");
        navigate("/company-list/new");
      };

    return (
        <div className="p-1">
            <header className="flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
                <h2 className="flex-auto my-auto text-3xl tracking-[2.4px]">
                    
                </h2>
                <button
                    className="justify-center px-3 py-2 mb-4 text-base text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
                    onClick={handleAddCompanyClick}
                >
                    Add a Company
                </button>
            </header>
            <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-3 p-3 bg-white w-full  rounded-[10px] shadow-sm">
                {companies.map((item, index) => (
                    <div key={index} className="col-span-1">
                        <CompanyListCard
                            listItem={item}
                            editCompanyClick={handleCompanyClick}
                        />
                    </div>
                ))}
            </div>
            {/* <div className="hidden xl:block xl:col-span-3">
                <UserProfileBoard
                    selectedUser={selectedUser}
                    onProfileClick={() => handleProfileClick(null)} // Clear selection on profile click
                    onHistoryClick={handleHistoryClick}
                />
            </div> */}
        </div>
    );
};

export default React.memo(CompanyList);
