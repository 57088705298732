// categoriesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: {},
  categories: [],
  loading: false,
  creating: false,
  updating: false,
  error: null,
  success: false,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    fetchCategoriesStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    fetchCategoriesSuccess(state, action) {
      state.categories = action.payload.data;
      state.loading = false;
      state.success = true;
    },
    fetchCategoriesFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.success = false;
    },
    createCategoryStart(state) {
      state.creating = true;
      state.error = null;
      state.success = false;
    },
    createCategorySuccess(state, action) {
      state.category = action.payload.data;
      state.creating = false;
      state.success = true;
    },
    createCategoryFailure(state, action) {
      state.creating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    updateCategoryStart(state) {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateCategorySuccess(state, action) {
      state.category = action.payload.data;
      state.updating = false;
      state.success = true;
    },
    updateCategoryFailure(state, action) {
      state.updating = false;
      state.error = action.payload.error;
      state.success = false;
    },
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  createCategoryStart,
  createCategorySuccess,
  createCategoryFailure,
  updateCategoryStart,
  updateCategorySuccess,
  updateCategoryFailure,
} = categoriesSlice.actions;

export const selectCategories = (state) => state.categories;

export default categoriesSlice.reducer;
