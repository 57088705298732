import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { selectForgotPassword } from "../../redux/slices/forgotPasswordSlice";
import { forgotPassword } from "../../redux/actions/authActions";

function ForgotPasswordScreen() {
  const [email, setEmail] = useState("");
  const { loading, error, success, data } = useSelector(selectForgotPassword);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (!loading && !error && success && data) {
      navigate("/email-verification-code", { state: { email, isForgotPassword: true } });
    }
  }, [data, error, success, loading, navigate, email]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("handleSubmit email:", email); // Here you would replace this with whatever you need to do with the email
    dispatch(forgotPassword(email));
  };

  return (
    <Grid container spacing={0} sx={{ height: "100vh" }}>
      {/* First Column: Logo */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "50%", textAlign: "right", position: "relative" }}>
          {/* Logo at the top corner */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f23ab2c1468d22a14aabb0b0349d11c5c8e83f20ef78bfce529298c7674f471a?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Logo"
            style={{
              width: "20%",
              aspectRatio: "0.5",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          />
          {/* Image in the center */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2dcc37416681c169a6af6013613cfcccf867f6e02399c212482099c6f18f3c8b?apiKey=5919a58685ca46fb99fef7a526577ea6"
            alt="Decorative"
            style={{
              width: "100%",
              aspectRatio: "1.0",
              marginTop: "40%",
              transform: "translateY(-40%)",
            }}
          />
        </Box>
      </Grid>

      {/* Second Column: Form */}

      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "#E6F2F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "80%", textAlign: "center" }}>
          <Box
            component="img"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3854a67a326f52c3489a732f0675a31c8c3814018ef091f4ce6551b7d41f8af0?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            sx={{ width: 80, mb: 10 }}
            alt="Email Verification"
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Forgot password
            </Typography>
            <Typography variant="body1">
              Enter your email for the verification process, we will send 4
              digits code to your email.
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 2, width: "100%", textAlign: "center" }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              id="email"
              label="Enter email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
              sx={{
                width: "100%",
                my: 3,
                py: 1.5,
                borderRadius: "50px",
                backgroundImage:
                  "linear-gradient(276deg, #F0BA47 8.16%, #F3D25C 63.6%)",
                fontWeight: "bold",
              }}
            >
              {loading ? "Sending..." : "Continue"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordScreen;
