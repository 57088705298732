// servicesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  service: {},
  services: [],
  allServices : [],
  loading: false,
  creating: false,
  updating: false,
  error: null,
  success: false,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    filterServices(state,action) {
      state.services=state.allServices.filter(x=>x.subCategory == action.payload)
    },
    fetchServicesStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    fetchServicesSuccess(state, action) {
      state.services = action.payload.data;
      state.allServices = action.payload.data;
      state.loading = false;
      state.success = true;
    },
    fetchServicesFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.success = false;
    },
    createServiceStart(state) {
      state.creating = true;
      state.error = null;
      state.success = false;
    },
    createServiceSuccess(state, action) {
      state.service = action.payload.data;
      state.creating = false;
      state.success = true;
    },
    createServiceFailure(state, action) {
      state.creating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    updateServiceStart(state) {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateServiceSuccess(state, action) {
      state.service = action.payload.data;
      state.updating = false;
      state.success = true;
    },
    updateServiceFailure(state, action) {
      state.updating = false;
      state.error = action.payload.error;
      state.success = false;
    },
  },
});

export const {
  fetchServicesStart,
  fetchServicesSuccess,
  fetchServicesFailure,
  createServiceStart,
  createServiceSuccess,
  createServiceFailure,
  updateServiceStart,
  updateServiceSuccess,
  updateServiceFailure,
  filterServices
} = servicesSlice.actions;

export const selectServices = (state) => state.services;

export default servicesSlice.reducer;
