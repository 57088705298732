import React, { useState, useEffect,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentPage } from "../redux/slices/currentPageSlice";
import { selectOTP } from "../redux/slices/verifyOtpSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppLogoNamed from "../assets/named_logo.svg";
import AppLogo from "../assets/logo.svg";
import { AppIcons } from "../assets";
import { selectApp, toggleSidebar } from "../redux/slices/appSlice";
import Cookies from 'js-cookie';
import { loginRemove } from "../redux/slices/loginSlice";
import AppConfig from '../config'
import{fetchCompany} from '../redux/actions/authActions'
import {AppContext} from '../config/AppContextProvider'


const Header = () => {
  const {companyData} = useSelector(selectOTP);
  const currentPage = useSelector(selectCurrentPage);
  const { companyId } = useContext(AppContext);
  const isSidebarOpen = useSelector(selectApp).isSidebarOpen;
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsProfileMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsProfileMenuOpen(false);
  };
  const handleProfileClick= () =>{
    setIsProfileMenuOpen(false);
    navigate("/CompanyProfile", { state: companyData });
  }

  const handleLogout = () => {
    Cookies.remove('tx');
    dispatch(loginRemove({}));
    navigate("/login");
  };

  useEffect(() => {
    console.log('callling fetch company')
    if(AppConfig.IS_COMP)
    dispatch(fetchCompany(companyId))
    // Update time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);


    
  }, []);

  return (
    <header className="bg-white fixed w-full z-[999] flex items-center h-[80px] shadow-sm">
      <div className=" mx-auto flex items-center justify-between w-full px-2 md:px-6">
        {/* 1st Section: Logo */}
        <div className="flex items-center w-[300px] gap-[20px]">
          {/* hamburger */}
          <button
            className="focus:outline-none block xl:hidden"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
          >
            {isSidebarOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="none"
                  stroke="#47619b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3.75 4.75l8.5 8.5m0-8.5l-8.5 8.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="none"
                  stroke="#47619b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
                />
              </svg>
            )}{" "}
          </button>
          <Link to="/">
            <img
              src={AppLogo}
              alt="Icon Logo"
              className="h-[55px] w-[57px] mr-2"
            />
          </Link>
          <Link to="/">
            <img
              src={AppLogoNamed}
              alt="Named Logo"
              className="h-[23px] mr-5 xl:mr-40 hidden md:block"
            />
          </Link>
        </div>

        {/* 2nd Section: Current Page Name */}
        <div className="flex-grow items-center justify-items-start bg-grey hidden md:block">
          <h6 className="text-[20px] font-bold m-0 xl:text-[24px]">
            {currentPage}
          </h6>
        </div>

        {/* 3rd Section: Time, Notifications, and User Profile */}
        <div className="flex items-center gap-3 w-full justify-end  md:w-auto xl:gap-9">
          {/* Replace this with the actual time */}
          <p className="text-[16px] font-medium m-0 xl:text-[20px] ">
            {currentTime.toLocaleTimeString()}
          </p>

          {/* Notifications Icon */}
          <button className="text-black relative hidden md:block">
            <div className="absolute -top-3 -right-4 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center font-medium">
              4
            </div>
            <img
              src={AppIcons.Notification}
              alt="Notification Icon"
              className="h-5 w-5"
            />
          </button>

          {/* User Profile with Dropdown */}
          <div className="relative inline-block text-left ml-2">
            <button
              onClick={handleMenuOpen}
              className="flex items-center text-black"
            >
              <div className="h-9 w-9 rounded-[50%] bg-slate-300 flex justify-center items-center mr-2 text-white font-medium">
                U
              </div>
              <ArrowDropDownIcon />
            </button>

            {/* User Profile Menu */}
            {isProfileMenuOpen && (
              <div
                className="absolute right-2 mt-2 w-48 bg-white rounded-md shadow-md"
                onBlur={() => {
                  setIsProfileMenuOpen(false);
                }}
              >
                <ul className="py-2">
                  { AppConfig.IS_COMP &&
                      <li
                      className="cursor-pointer px-2 py-2"
                      onClick={handleProfileClick}
                    >
                      Profile
                    </li>
                  }
                  
                  {/* <li
                    className="cursor-pointer px-2 py-2"
                    onClick={handleMenuClose}
                  >
                    My Account
                  </li> */}
                  {/* Notification */}
                  <li
                    className="cursor-pointer px-2 py-2 relative  md:hidden flex justify-between items-center"
                    onClick={handleMenuClose}
                  >
                    Notifications
                    <div className=" bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center font-medium">
                      4
                    </div>
                  </li>

                  <li
                    className="cursor-pointer px-2 py-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
