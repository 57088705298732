import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppIcons, AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";

function OfferImage({ offerPicture, onChangeOfferPicture }) {
  return (
    <div className="flex gap-3 justify-between self-start mt-16 font-semibold tracking-widest text-center text-white max-md:mt-10">
      <img
        loading="lazy"
        src={offerPicture || AppImages.Placeholder}
        alt="Offer Image"
        className="shrink-0 max-w-full rounded-xl border-solid aspect-rectangle border-[7px] border-zinc-100 w-[349px]"
      />
      <button
        onClick={onChangeOfferPicture}
        className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
      >
        Change Picture
      </button>
    </div>
  );
}

function AddEditOffer() {
  const location = useLocation();
  const offer = location.state;

  const navigate = useNavigate();

  const [offerTitle, setOfferTitle] = useState("");
  const [discount, setDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [offerPicture, setOfferPicture] = useState(null);
  useEffect(() => {
    // Populate the input fields with offer data when in edit mode
    if (offer) {
      setOfferTitle(offer.title);
      setDiscount(offer.discount);
      setDescription(offer.description);
      setOfferPicture(offer.backgroundImage);
    }
  }, [offer]);

  const handleOfferTitleChange = (e) => {
    setOfferTitle(e.target.value);
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDeleteOfferClick = () => {};

  const handleChangeOfferPicture = () => {
    pickFile([FileTypes.IMAGE], (result) => {
      // Handle the selected file result here
      console.log(result);
      setOfferPicture(result);
    });
  };

  const handleSave = () => {
    // Implement save logic here console.log("Save button clicked");
  };
  const handleCancel = () => {
    // Implement cancel logic here console.log("Cancel button clicked");
  };

  return (
    <section className="flex flex-col px-8 py-8 text-lg bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="flex gap-5 justify-between font-bold max-md:flex-wrap max-md:max-w-full">
        <button
          className="w-31 h-77 px-2 rounded-md flex-shrink-0 rounded-10 bg-blue-100"
          onClick={() => navigate(-1)}
        >
          <img loading="lazy" src={AppIcons.ChevronRight} alt="Profile Image" />
        </button>
        <button
          className="justify-center px-3 py-2 text-xl text-white rounded-lg bg-[#FC2C5A] tracking-[2px] max-md:px-5"
          onClick={handleDeleteOfferClick}
        >
          Delete Offer
        </button>
      </header>

      <OfferImage
        offerPicture={offerPicture}
        onChangeOfferPicture={handleChangeOfferPicture}
      />
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
        <FieldInput
          label="Offer Heading"
          value={offerTitle}
          onChange={handleOfferTitleChange}
          placeholder="Enter offer name"
        />
        <FieldInput
          label="Offer Discount"
          value={discount}
          onChange={handleDiscountChange}
          placeholder="Enter discount"
        />
      </div>
      <FieldInput
        label="Offer Description"
        value={description}
        onChange={handleDescriptionChange}
        placeholder="Enter description"
        type="textarea"
      />
      <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-2xl font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
        <button
          onClick={handleSave}
          className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
        >
          {offer ? "Save" : "Add Offer"}
        </button>
        <button
          onClick={handleCancel}
          className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
        >
          Cancel
        </button>
      </div>
    </section>
  );
}

export default AddEditOffer;
