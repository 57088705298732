// Inside the FieldInput component
import Switch from "react-switch";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function FieldInput({ label, value, onChange, placeholder, type = "text" ,disabled}) {
  const InputField =
    type === "textarea" ? (
      <textarea
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none resize-none"
        rows="4"
      />
    ) : type === "switch" ? (
      <label htmlFor={label} className="flex ml-3 items-center cursor-pointer">
        <Switch
          onChange={onChange}
          checked={value}
          onColor="#10B981"
          offColor="#E5E7EB"
          onHandleColor="#fff"
          offHandleColor="#fff"
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={48}
          className="react-switch"
        />
        <span className="ml-3 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal">
          {placeholder}
        </span>
      </label>
    ) :
    type === "phone" ? (
      <PhoneInput
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    defaultCountry="AE"
                    international
  countryCallingCodeEditable={false}
                    className="w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none"
        />
              
    ) : (
      <input
        id={label}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none"
      />
    );

  return (
    <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
      <label
        htmlFor={label}
        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
      >
        {label}
      </label>
      <div className=" grow justify-center items-start px-1 py-3 mt-1.5 w-full rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
        {InputField}
      </div>
    </div>
  );
}

export default FieldInput;
