// rootReducer.js
import { combineReducers } from "redux";
import currentPageReducer from "./slices/currentPageSlice";
import appReducer from "./slices/appSlice";
import loginReducer from "./slices/loginSlice";
import verifyOtpReducer from "./slices/verifyOtpSlice";
import forgotPasswordReducer from "./slices/forgotPasswordSlice";
import companiesReducer from "./slices/companiesSlice"
import updatePasswordReducer from "./slices/updatePasswordSlice";
import servicesReducer from "./slices/servicesSlice";
import imageUploadReducer from "./slices/imageUploadSlice"
import categorySlice from "./slices/categorySlice";
import subCategorySlice from "./slices/subCategorySlice";
import subServicesSlice from "./slices/subServiceSlice"
import staffListSlicer from "./slices/staffListSlicer";
import professionsSlice from "./slices/professionsSlice";

const rootReducer = combineReducers({
  updatePassword: updatePasswordReducer,
  forgotPassword: forgotPasswordReducer,
  verifyOtp: verifyOtpReducer,
  login: loginReducer,
  companies : companiesReducer,
  professions: professionsSlice,
  imageUpload : imageUploadReducer,
  categories : categorySlice,
  subCategories : subCategorySlice,
  services: servicesReducer,
  subServices : subServicesSlice,
  staffList : staffListSlicer,
  currentPage: currentPageReducer,
  app: appReducer,
});

export default rootReducer;
