/* eslint-disable react/prop-types */
import React, { useState } from "react";
import totalncomingImg from "../assets/totalIncoming.png";
import totalOutgoingImg from "../assets/totalOutgoing.png";
import totaProfitImg from "../assets/totalProfit.png";
import totaEarningImg from "../assets/totalEarning.png";
import totaPendingImg from "../assets/totaPending.png";

const TableComponent = ({ header, data }) => {
  return (
    <div className="bg-white shadow-md rounded-lg mt-6">
      {/* Filter Section */}
      <div className="flex justify-between items-center p-3 border-b">
        <select
          className="border border-gray-300 text-sm rounded-md px-3 py-2"
          defaultValue=""
        >
          <option value="">Filter</option>
          <option value="Company1">Company 1</option>
          <option value="Company2">Company 2</option>
        </select>
        <button className="p-2 hover:bg-gray-100 rounded-full">
          <svg
            className="w-5 h-5 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 4a2 2 0 012-2h14a2 2 0 012 2v2a2 2 0 01-2 2H5a2 2 0 01-2-2V4zM3 14a2 2 0 012-2h14a2 2 0 012 2v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-2z"
            />
          </svg>
        </button>
      </div>

      {/* Scrollable Table */}
      <div className="overflow-x-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-50">
              {header?.map((text) => (
                <th
                  key={text}
                  className="px-4 py-2 font-semibold text-gray-700 text-sm"
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                className="border-b hover:bg-gray-50 transition-colors"
              >
                <td className="px-4 py-2">{row.company}</td>
                <td className="px-4 py-2">{row.total}</td>
                <td className="px-4 py-2 text-red-500">-AED {row.withdrawn}</td>
                <td className="px-4 py-2 text-green-500">
                  AED {row.withdrawal}
                </td>
                <td className="px-4 py-2">
                  <span className="text-blue-500 cursor-pointer">Details</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TransactionTableTwo = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => setActiveTab(index);

  const data = [
    {
      company: "Company Name",
      total: "87364",
      withdrawn: "3746",
      withdrawal: "3746",
    },
    {
      company: "Company Name",
      total: "87364",
      withdrawn: "3746",
      withdrawal: "3746",
    },
    {
      company: "Company Name",
      total: "87364",
      withdrawn: "3746",
      withdrawal: "3746",
    },
  ];

  const stats = [
    { img: totalncomingImg, label: "Total Incoming", value: "23434" },
    { img: totalOutgoingImg, label: "Total Outgoings", value: "348" },
    { img: totaProfitImg, label: "Total Profit", value: "23132" },
    { img: totaEarningImg, label: "Total Earning", value: "348" },
    { img: totaPendingImg, label: "Payment Pendings", value: "348" },
  ];

  const tabs = ["Outgoings", "Incomings", "Transactions"];

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Statistics Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 mb-8">
        {stats.map((item, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" // Centering added here
          >
            <img src={item.img} alt={item.label} className="w-14 h-14 mb-4" />
            <p className="text-sm text-gray-500 text-center md:text-left">
              {item.label}
            </p>
            <h3 className="text-xl font-semibold text-center md:text-left">
              AED {item.value}
            </h3>
          </div>
        ))}
      </div>

      {/* Tabs Section */}
      <div className="border-b mb-4">
        <div className="flex space-x-6">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabChange(index)}
              className={`py-2 font-medium ${
                activeTab === index ? "text-black font-bold" : "text-gray-500"
              } text-sm xs:text-xs`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Table Section */}
      <TableComponent
        header={[
          "Company",
          "Total Amount",
          "Withdrawn",
          "Withdrawal",
          "Action",
        ]}
        data={data}
      />
    </div>
  );
};

export default TransactionTableTwo;
