import {fetchAllEntities} from "./baseHalo.api"

export const fetchBookingsAPI = async (pageNumber,rows,dispatch) => {
    try {
      
      const response = await fetchAllEntities(`bookings/?page=${pageNumber}&limit=${rows}`, dispatch);
     
      return response;
    } catch (error) {
      throw new Error("Failed to fetch companies: " + error.message);
    }
  };

  
export const fetchAllStats = async (dispatch) => {
  try {
    
    const response = await fetchAllEntities(`stats`, dispatch);
   console.log(response)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch fetchAllStats: " + error.message);
  }
};

