// subCategoriesActions.js
import {
  fetchSubCategoriesStart,
  fetchSubCategoriesSuccess,
  fetchSubCategoriesFailure,
  createSubCategoryStart,
  createSubCategorySuccess,
  createSubCategoryFailure,
  updateSubCategoryStart,
  updateSubCategorySuccess,
  updateSubCategoryFailure,filterSubCats
} from "../slices/subCategorySlice";

import {
  createSubCategoryAPI,
  updateSubCategoryAPI,
  fetchSubCategoriesAPI,
} from "../../api/services.api";

export const fetchSubCategories = () => async (dispatch) => {
  try {
    dispatch(fetchSubCategoriesStart());
    const subCategories = await fetchSubCategoriesAPI(dispatch);
    dispatch(fetchSubCategoriesSuccess(subCategories));
  } catch (error) {
    dispatch(fetchSubCategoriesFailure(error.message));
  }
};
export const fetchSubCatefories = () => async (dispatch) => {
  try {
    dispatch(fetchSubCategoriesStart());
    const subCategories = await fetchSubCategoriesAPI(dispatch);
    dispatch(fetchSubCategoriesSuccess(subCategories));
  } catch (error) {
    dispatch(fetchSubCategoriesFailure(error.message));
  }
};

export const createSubCategory = (subCategoryData) => async (dispatch) => {
  try {
    dispatch(createSubCategoryStart());
    await createSubCategoryAPI(subCategoryData, dispatch);
    dispatch(createSubCategorySuccess());
  } catch (error) {
    dispatch(createSubCategoryFailure(error.message));
  }
};

export const updateSubCategory = ( subCategoryData) => async (dispatch) => {
  try {
    dispatch(updateSubCategoryStart());
    await updateSubCategoryAPI(subCategoryData,dispatch);
    dispatch(updateSubCategorySuccess());
  } catch (error) {
    dispatch(updateSubCategoryFailure(error.message));
  }
};
export const filterCategories = ( catId) => async (dispatch) => {
    dispatch(filterSubCats(catId))
  };


