import AppLogoNamed from "./named_logo.svg";
import AppLogo from "./logo.svg";

import Overview from "./icon_overview.svg";
import Message from "./icon_message.svg";
import Settings from "./icon_setting.svg";
import Services from "./icon_services.svg";
import Payments from "./icon_payment.svg";
import Offers from "./icon_offers.svg";
import Calendar from "./icon_calendar.svg";
import Clock from "./icon_clock.svg";
import User from "./icon_user.svg";

import OverviewActive from "./icon_overview_active.svg";
import MessageActive from "./icon_message_active.svg";
import SettingsActive from "./icon_setting_active.svg";
import ServicesActive from "./icon_services_active.svg";
import PaymentsActive from "./icon_payment_active.svg";
import OffersActive from "./icon_offers_active.svg";
import CalendarActive from "./icon_calendar_active.svg";
import ClockActive from "./icon_clock_active.svg";
import UserActive from "./icon_user_active.svg";

import ChevronLeft from "./chevron_left.svg";
import ChevronRight from "./chevron_right.svg";
import Notification from "./icon_notification.svg";
import Search from "./icon_search.svg";
import UserImage from "./user_profile_chat.svg";
import Send from "./icon_send.svg";
import Attachment from "./icon_attachment.svg";
import Edit from "./icon_edit.svg";
import MapMarker from "./map_marker.svg";
import Placeholder from "./image_placeholder.png";

export const AppIcons = {
  User,
  Overview,
  Message,
  Settings,
  Services,
  Payments,
  Offers,
  Calendar,
  Clock,
  UserActive,
  OverviewActive,
  MessageActive,
  SettingsActive,
  ServicesActive,
  PaymentsActive,
  OffersActive,
  CalendarActive,
  ClockActive,
  ChevronLeft,
  ChevronRight,
  Notification,
  Search,
  UserImage,
  Send,
  Attachment,
  Edit,
  MapMarker
};

export const AppImages = {
  Placeholder
}

export const Logos = {
  AppLogo,
  AppLogoNamed,
};
