import React, { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch, useSelector } from "react-redux";
import { selectUpdatePassword } from "../../redux/slices/updatePasswordSlice";
import { updatePassword } from "../../redux/actions/profileActions";

const PasswordInput = ({ label, onPasswordChange, placeholder }) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="body1" gutterBottom>
      {label}
    </Typography>
    <TextField
      variant="outlined"
      fullWidth
      type="password"
      onChange={onPasswordChange}
      placeholder={placeholder}
      InputProps={{
        endAdornment: <LockOutlinedIcon />,
      }}
    />
  </Box>
);

function ResetPasswordScreen() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const { userId } = location.state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, data } = useSelector(selectUpdatePassword);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (newPassword === confirmPassword) {
      dispatch(updatePassword(userId, newPassword));
      // Redirect or show success message based on passwordState
      navigate("/success-message");
    }
  };

  return (
    <Grid container spacing={0} sx={{ height: "100vh" }}>
      {/* First Column: Logo */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "50%", textAlign: "right", position: "relative" }}>
          {/* Logo at the top corner */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f23ab2c1468d22a14aabb0b0349d11c5c8e83f20ef78bfce529298c7674f471a?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Logo"
            style={{
              width: "20%",
              aspectRatio: "0.5",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          />
          {/* Image in the center */}
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2dcc37416681c169a6af6013613cfcccf867f6e02399c212482099c6f18f3c8b?apiKey=5919a58685ca46fb99fef7a526577ea6"
            alt="Decorative"
            style={{
              width: "100%",
              aspectRatio: "1.0",
              marginTop: "40%",
              transform: "translateY(-40%)",
            }}
          />
        </Box>
      </Grid>

      {/* Second Column: Form */}
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "#E6F2F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 9 }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9d704b208c1ae17918d2fc3f7394b113ad938dda6127bb4c928b699dd3dd8256?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Verification step illustration"
            style={{ width: "100%", maxWidth: "320px", height: "auto" }}
          />
        </Box>
        <Box sx={{ width: "80%", textAlign: "center" }}>
          <Typography component="h1" variant="h5">
            New Password
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, textAlign: "start" }}>
            Set the new password for your account so you can log in and access
            all features.
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={2} sx={{ textAlign: "start" }}>
              <Grid item xs={12}>
                <PasswordInput
                  label="Enter new password"
                  onPasswordChange={handleNewPasswordChange}
                  placeholder={"8 symbols at least"}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  label="Confirm password"
                  onPasswordChange={handleConfirmPasswordChange}
                  placeholder={"8 symbols at least"}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    my: 3,
                    width: "100%",
                    py: 1.5,
                    borderRadius: "50px",
                    backgroundImage:
                      "linear-gradient(276deg, #F0BA47 8.16%, #F3D25C 63.6%)",
                    fontWeight: "bold",
                  }}
                >
                  {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, py: 2 }}
                > */}
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordScreen;
