// utils.js
export function pickPicture(callback) {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";

  input.onchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      callback(file);
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.readAsArrayBuffer(file);
      // reader.onloadend = () => {
      //   callback(reader.result);
      // };
    }
  };

  input.click();
}

// utils.js
export function pickFile(acceptedFileTypes, callback) {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = acceptedFileTypes.join(",");

  input.onchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      callback(file);
    }
  };

  input.click();
}

export async function createImageObjectURL(data) {
  try {
    const imageData = await data.arrayBuffer();
    const blob = new Blob([imageData], { type: "image/png" });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    console.error("Error creating object URL:", error);
  }
};
