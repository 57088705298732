import React, { createContext, useState, useEffect } from "react";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(() => {
    const savedCompanyId = localStorage.getItem('companyId');
    return savedCompanyId || '';
  });

  useEffect(() => {
    localStorage.setItem('companyId', companyId);
  }, [companyId]);

  return (
    <AppContext.Provider value={{ companyId, setCompanyId }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
