import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities/";
import { useDispatch, useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import {
  createSubCategory,
  updateSubCategory,
} from "../../redux/actions/subCategoryAction";
import { selectImageUpload } from "../../redux/slices/imageUploadSlice";
import { uploadImageAction } from "../../redux/actions/imageUploadActions";
import { fetchImage } from "../../api/imageUpload.api";
import DropdownSelector from "../../components/DropDownSelector";

import { selectCategories } from "../../redux/slices/categorySlice";
import { fetchCategories } from "../../redux/actions/categoryActions";

import { selectProfessions } from "../../redux/slices/professionsSlice";
import { fetchProfessions } from "../../redux/actions/professionsActions";

function CoverImage({ coverPhoto, onChangeCoverPicture }) {

  const [image, setImage] = useState(null);

  useEffect(() => {
    console.log("coverPhoto", coverPhoto);
    if (coverPhoto) {
      setImage(coverPhoto);
    }
  }, [coverPhoto]);

  return (
    <div className="flex overflow-hidden relative flex-col items-end justify-end px-16 pt-20 pb-8 font-semibold tracking-widest text-center text-white min-h-[327px] bg-[#EEEEEE] max-md:px-5 max-md:max-w-full">
      {image ? (
        <img
          loading="lazy"
          src={image || AppImages.Placeholder}
          alt="Cover Image"
          className="object-cover absolute inset-0 size-full"
        />
      ) : (
        <p>Loading...</p>
      )}
      <button
        onClick={onChangeCoverPicture}
        className="absolute bottom-0 right-0 justify-center px-4 py-3 mr-4 mb-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5 max-md:mt-10"
      >
        Change Cover Picture
      </button>
    </div>
  );
}

function ProfileImage({ profilePicture, onChangeProfilePicture }) {
  // const { loading, error, image } = useSelector(selectImageUpload);
  // const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  useEffect(() => {
    console.log("profilePicture", profilePicture);
    if (profilePicture) {
      setImage(profilePicture);
    }
  }, [profilePicture]);

  return (
    <div className="flex gap-3 justify-between self-start mt-16 font-semibold tracking-widest text-center text-white max-md:mt-10">
      {image ? (
        <img
          loading="lazy"
          src={image || AppImages.Placeholder}
          alt="Profile Image"
          className="shrink-0 max-w-full rounded-full border-solid aspect-square border-[7px] border-zinc-100 w-[149px]"
        />
      ) : (
        <p>Loading...</p>
      )}
      <button
        onClick={onChangeProfilePicture}
        className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
      >
        Change Picture
      </button>
    </div>
  );
}

function AddEditSubCategory() {
  const location = useLocation();
  const subCategory = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { categories } = useSelector(selectCategories);
  const { professions } = useSelector(selectProfessions);
  const { loading, error, imageURL } = useSelector(selectImageUpload);

  const [subCategoryName, setSubCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [coverPhotoURL, setCoverPhotoURL] = useState(null);
  const [category, setCategory] = useState(null);
  const [profession, setProfession] = useState(null);
  const [isBookable, setIsBookable] = useState(true);
  const [price, setPrice] = useState(0)
  const categoriesLoaded = useRef(false);
  const [serviceMode, setServiceMode] = useState(null)
  const [serviceType, setServiceType] = useState(null);

  const serviceModes = [{
    id : "HR",
    name: "Hourly"
  },
  {
    id : "MS",
    name: "Milestone"
  }]
  const serviceTypes = [{
    id : "SE",
    name: "Start Time"
  },
  {
    id : "PD",
    name: "Pick Up & Drop Off"
  }]
  

  useEffect(() => {
    dispatch(fetchCategories());
    if (!professions || professions.length === 0) dispatch(fetchProfessions());
    if (subCategory) {
      setSubCategoryName(subCategory.name);
      setDescription(subCategory.description);
      setCoverPhotoURL(subCategory.image);
      setProfilePictureURL(subCategory.icon);
      setIsActive(subCategory.isActive);
      setIsBookable(subCategory.bookable);
      // setProfession(subCategory.profession);
      setPrice(subCategory.price)
      setServiceMode(serviceModes.find(x=>x.id===subCategory.serviceMode))
      setServiceType(serviceTypes.find(x=>x.id===subCategory.serviceType))
      fetchImage(subCategory.image).then((img) => setCoverPhoto(img));
      fetchImage(subCategory.icon).then((img) => setProfilePicture(img));
    }
  }, [subCategory]);

  useEffect(() => {
    if (
      subCategory &&
      categories &&
      categories.length > 0 &&
      !categoriesLoaded.current
    ) {
      const foundCategory = categories.find(
        (cat) => cat.id === subCategory.category
      );
      setCategory(foundCategory);
      categoriesLoaded.current = true;
    }
  }, [categories, subCategory]);

  useEffect(() => {
    if (!professions || professions.length === 0) dispatch(fetchProfessions());
    
  }, [dispatch]);
  useEffect(() => {
    
    if (
      professions &&
      professions.length > 0 
    ) {
      const foundProfession = professions.find(
        (p) => p._id === subCategory.profession
      );
      setProfession(foundProfession);
    }
    
  }, [professions, subCategory]);

  // useEffect(() => {
  //   console.log("imageURL", imageURL);
  //   if (imageURL) {
  //     // Check the subfolder to determine which image URL it is
  //     if (imageURL.includes("cover")) {
  //       // Update cover photo URL state
  //       setCoverPhoto(imageURL);
  //     } else if (imageURL.includes("icon")) {
  //       // Update profile picture URL state
  //       setProfilePicture(imageURL);
  //     }
  //   }
  // }, [imageURL]);

  const handleSubCategoryNameChange = (e) => {
    setSubCategoryName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // Function to handle toggle of the switch
  const handleActiveToggle = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };
  const handleServiceMode = (item)=>{
    setServiceMode(item);
    if(item.id === 'HR')
    {
      setServiceType(serviceTypes.filter(x=>x.id==='SE')[0])
    }
  }

  const handleBookableToggle = () => {
    setIsBookable((prevIsActive) => !prevIsActive);
    setServiceMode(null)
    setServiceType(null)
    setPrice(0)
  };
  const handlePriceChange = (e)=>{
    setPrice(e.target.value)
  }
  const handleChangeCoverPicture = () => {
    pickFile([FileTypes.IMAGE], async (result) => {
      dispatch(
        uploadImageAction("categories", "cover", result, (path) => {
          setCoverPhotoURL(path);
          setCoverPhoto(URL.createObjectURL(result));
        })
      );
    });
  };

  const handleChangeProfilePicture = () => {
    pickFile([FileTypes.IMAGE], async (result) => {
      dispatch(
        uploadImageAction("categories", "icon", result, (path) => {
          setProfilePictureURL(path);
          setProfilePicture(URL.createObjectURL(result));
        })
      );
    });
  };
  const validateForm = () => {
    let isInvalid = false;
    let millisec = 0;

    if (!subCategoryName) {
        setTimeout(() => {
            toast.error("SubCategory Name is required");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    if (!description) {
        setTimeout(() => {
            toast.error("Description is required");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    if (!category) {
        setTimeout(() => {
            toast.error("Category is required");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    if (!isBookable) {
        if (serviceMode) {
            setTimeout(() => {
                toast.error("Service Type is required when Bookable");
            }, millisec);
            millisec += 1000;
            isInvalid = true;
        }
    }

    if (isBookable && price <= 0) {
        setTimeout(() => {
            toast.error("Price should be greater than 0");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    if (isBookable && !serviceMode) {
        setTimeout(() => {
            toast.error("Service Mode is required when Bookable");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    if (isBookable && serviceMode && !serviceType) {
        setTimeout(() => {
            toast.error("Service Type is required when Bookable and Service Mode is selected");
        }, millisec);
        millisec += 1000;
        isInvalid = true;
    }

    return !isInvalid;
};
const handleSave = () => {
  if (!validateForm()) return;

  const updatedSubCategoryData = {
      name: subCategoryName,
      description: description,
      isActive: isActive,
      image: coverPhotoURL,
      icon: profilePictureURL,
      category: category ? category.id : null,
      profession: profession ? profession._id : null,
      bookable: isBookable,
      price: isBookable ? price : null,
      serviceMode: serviceMode ? serviceMode.id : null,
      serviceType: serviceType ? serviceType.id : null,
  };

  // Add ID if updating an existing subCategory
  if (subCategory) {
      updatedSubCategoryData.id = subCategory.id;
      dispatch(updateSubCategory(updatedSubCategoryData));
  } else {
      dispatch(createSubCategory(updatedSubCategoryData));
  }

  //navigate(-1);
};

  const categorySelected = (obj) => {
    setCategory(obj);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <CoverImage
        coverPhoto={coverPhoto}
        onChangeCoverPicture={handleChangeCoverPicture}
      />
      <ProfileImage
        profilePicture={profilePicture}
        onChangeProfilePicture={handleChangeProfilePicture}
      />
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
        <FieldInput
          label="SubCategory Name"
          value={subCategoryName}
          onChange={handleSubCategoryNameChange}
          placeholder="Enter subCategory name"
        />
        <FieldInput
          label="Active/Inactive"
          value={isActive}
          onChange={handleActiveToggle}
          type="switch"
          placeholder="Active"
        />
        <DropdownSelector
          label="Category"
          items={categories}
          selectedItem={category}
          onSelect={setCategory}
        />
        {professions && (
          <DropdownSelector
            label="Profession"
            items={professions}
            selectedItem={profession}
            onSelect={setProfession}
          />
        )}
        
      </div>
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
        {/* <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle
                className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none"
                variant="success"
                id="dropdown-basic"
              >
                {category == null ? "Select Category" : category.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {categories.map((category, index) => (
                  <Dropdown.Item
                    onClick={() => {
                      categorySelected(category);
                    }}
                  >
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
        <FieldInput
          label="Bookable Item ?"
          value={isBookable}
          onChange={handleBookableToggle}
          type="switch"
          placeholder=""
        />
        
          <FieldInput
            label="Price"
            value={price}
            onChange={handlePriceChange}
            type="number"
            placeholder="Active"
            disabled={!isBookable}
          />
        
        
          <DropdownSelector
            label="Service Mode"
            items={serviceModes}
            selectedItem={serviceMode}
            onSelect={handleServiceMode}
            disabled={!isBookable}
          />
        
       
        <DropdownSelector
        label="Service Type"
        items={serviceTypes}
        selectedItem={serviceType}
        onSelect={setServiceType}
        disabled={(serviceMode==null || serviceMode.id === 'HR')}
      />
        {(!isBookable)&&(serviceMode?.id==='HR')}
      </div>

      <FieldInput
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        placeholder="Enter description"
        type="textarea"
      />

      <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
        <button
          onClick={handleSave}
          className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
        >
          {subCategory ? "Save" : "Add SubCategory"}
        </button>
        <button
          onClick={handleCancel}
          className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
        >
          Cancel
        </button>
      </div>
    </section>
  );
}

export default AddEditSubCategory;
