import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../redux/slices/currentPageSlice";
import AppConfig from "../config"

// Import your icons here
import { AppIcons } from "../assets";
import { selectApp, toggleSidebar } from "../redux/slices/appSlice";

const menuItems = [
  {
    imgSrc: AppIcons.Overview,
    acticveImgSrc: AppIcons.OverviewActive,
    title: "Overview",
    alt: "Overview Icon",
    path: "overview",
    
  },
  {
    imgSrc: AppIcons.Calendar,
    acticveImgSrc: AppIcons.CalendarActive,
    title: "Calendar",
    alt: "Calendar Icon",
    path: "calendar",
  },
  {
    imgSrc: AppIcons.User,
    acticveImgSrc: AppIcons.UserActive,
    title: "Company List",
    alt: "Company List Icon",
    path: "company-list",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Clock,
    acticveImgSrc: AppIcons.ClockActive,
    title: "Appointments",
    alt: "Appointments Icon",
    path: "appointments",
  },
  {
    imgSrc: AppIcons.User,
    acticveImgSrc: AppIcons.UserActive,
    title: "Professionals List",
    alt: "Professionals List",
    path: "staff-list",
  },
  {
    imgSrc: AppIcons.Message,
    acticveImgSrc: AppIcons.MessageActive,
    title: "Message",
    alt: "Message Icon",
    path: "messages",
  },
  {
    imgSrc: AppIcons.Payments,
    acticveImgSrc: AppIcons.PaymentsActive,
    title: "Payment Information",
    alt: "Payments Icon",
    path: "payment-information",
  },
  {
    imgSrc: AppIcons.Services,
    acticveImgSrc: AppIcons.ServicesActive,
    title: "Category",
    alt: "Category Icon",
    path: "categories",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Services,
    acticveImgSrc: AppIcons.ServicesActive,
    title: "Sub Category",
    alt: "Category Icon",
    path: "subCategories",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Services,
    acticveImgSrc: AppIcons.ServicesActive,
    title: "Services",
    alt: "Services Icon",
    path: "services",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Services,
    acticveImgSrc: AppIcons.ServicesActive,
    title: "Sub Services",
    alt: "Services Icon",
    path: "subServices",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Settings,
    acticveImgSrc: AppIcons.SettingsActive,
    title: "Professions",
    alt: "Message Icon",
    path: "profession",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Offers,
    acticveImgSrc: AppIcons.OffersActive,
    title: "Offers",
    alt: "Offers Icon",
    path: "offers",
    accessControl: "admin"
  },
  {
    imgSrc: AppIcons.Settings,
    acticveImgSrc: AppIcons.SettingsActive,
    title: "Settings",
    alt: "Settings Icon",
    path: "settings",
  },
];

// Conditionally filter the menu items based on AppConfig.IS_COMP
const filteredMenuItems = AppConfig.IS_COMP
  ? menuItems.filter(item => !item.accessControl ||item.accessControl=="comp")
  : menuItems;

const MenuItem = ({
  imgSrc,
  activeImgSrc,
  title,
  alt,
  path,
  isActive,
  onMenuItemClick,
}) => (
  <button
    onClick={() => onMenuItemClick(path)}
    className={`flex items-center ${
      isActive ? "bg-[#e6f2f6]" : ""
    } px-[25px] py-[16px] w-full rounded-[10px] focus:outline-none gap-[18px]`}
  >
    <img
      src={isActive ? activeImgSrc : imgSrc}
      alt={alt}
      className="w-auto h-6 mr-2"
    />
    <span className=" text-black text-[14px] font-medium">{title}</span>
  </button>
);

function DashboardPanel() {
  const isSidebarOpen = useSelector(selectApp).isSidebarOpen;
  const [activeMenuItem, setActiveMenuItem] = useState(
    filteredMenuItems?.find((item) => window.location.pathname.includes(item.path))
      ?.path
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    setCurrentDate(formatDate(today));
    const pageName = filteredMenuItems.find(
      (item) => item.path === activeMenuItem
    )?.title ?? "";
    dispatch(setCurrentPage(pageName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuItemClick = (menuItem) => {
    console.log(`${menuItem} clicked`);
    setActiveMenuItem(menuItem);
    const pageName = filteredMenuItems.find((item) => item.path === menuItem).title;
    dispatch(setCurrentPage(pageName));
    navigate(
      `./${
        menuItem === "messages"
          ? menuItem.toLowerCase() + "/0"
          : menuItem.toLowerCase()
      }`
    );
    dispatch(toggleSidebar());
  };
  const formatDate = (date) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  const [currentDate, setCurrentDate] = useState('');



  let containerClass = `fixed top-16 left-[-100vw] w-[100vw] xl:w-auto transition-all xl:left-0 flex flex-col mt-2 p-2 bg-[#fff] rounded-md z-10 h-[calc(100%-80px)] overflow-auto mr-6 no-scrollbar  shadow-sm`;
  if (isSidebarOpen) {
    containerClass = `fixed top-16 left-0 w-[calc(100vw-16px)] xl:w-auto transition-all xl:left-0 flex flex-col mt-2 p-2 bg-[#fff] rounded-md z-10 h-[calc(100%-80px)] overflow-auto  no-scrollbar`;
  }

  return (
    <div className={containerClass}>
      <div className="mt-[30px] mr-4 xl:mr-0 ml-2 px-[18px] py-[21px] bg-[#e6f2f6] rounded-xl">
        <h6 className="font-bold bg-gradient-to-r from-[#01A0AA] to-[#076B9E] inline-block text-transparent bg-clip-text text-[18px] m-0 mb-3">
          General
        </h6>{" "}
        <p className="font-medium m-0">{currentDate}</p>
      </div>
      <div className="ml-1 mt-3">
        {filteredMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            imgSrc={item.imgSrc}
            activeImgSrc={item.acticveImgSrc}
            title={item.title}
            alt={item.alt}
            path={item.path}
            isActive={item.path === activeMenuItem}
            onMenuItemClick={handleMenuItemClick}
          />
        ))}
      </div>
    </div>
  );
}

export default DashboardPanel;
