import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppIcons } from "../assets";

const MessageCard = ({
  imageSrc,
  name,
  message,
  date,
  altText,
  onClick,
  index,
  isActive,
}) => (
  <Link
    className={`mb-2 flex flex-row items-center py-2 my-2 px-2 no-underline text-black rounded-xl ${isActive && "bg-[#0679A21A]"}`}
    to={`/messages/${index}`}
  >
    <div className="flex flex-row items-center w-full cursor-pointer">
      <img
        src={imageSrc}
        alt={altText}
        className="w-14 h-14 mr-2 rounded-full"
      />
      <div className="flex-1">
        <p className="text-base font-bold m-0">{name}</p>
        <p className="text-sm text-gray-600 m-0 mt-1">{message}</p>
      </div>
      <p className="text-xs text-gray-400 whitespace-nowrap m-0">{date}</p>
    </div>
  </Link>
);

const MessageBox = ({ containerClass, showProfile }) => {
  const [searchText, setSearchText] = useState("");
  const location = useLocation();

 
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleCardClick = (name) => {
    console.log(`${name}'s message was clicked`);
  };

  const messages = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "Ahsen: I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "You: I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7e73865d7d31557d364fa513254666370ae0e0dff2300d5e8e6f3bf8a936a9a9?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "You: I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7e73865d7d31557d364fa513254666370ae0e0dff2300d5e8e6f3bf8a936a9a9?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "You: I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e4ddd6e3d91bb9280d2d916cbb29be09f5147ad04e5c5115b6c645adf7db97c3?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7e73865d7d31557d364fa513254666370ae0e0dff2300d5e8e6f3bf8a936a9a9?apiKey=5919a58685ca46fb99fef7a526577ea6&",
      name: "Ahsen Shamil Last",
      message: "I'll be there in 5 mins",
      date: "Sep 10",
      altText: "Profile picture of Ahsen Shamil",
    },
  ];

  return (
    <div
      className={`w-full h-full overflow-y-auto no-scrollbar bg-white rounded-xl  p-4 ${containerClass} shadow-sm`}
    >
      {showProfile && (
        <div className="flex gap-[19px] mb-7 items-center">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/96447c80c351db9669bc0c53c559f4acac52ae9aac643be3e1b98cf49041ec82?apiKey=5919a58685ca46fb99fef7a526577ea6&"
            alt="Profile picture of Ahsen Shamil"
            className="h-16 w-16 rounded-s-2xl"
          />
          <div>
            <h3 className="text-[16px] font-bold m-0">Ahsen Shamil</h3>
            <p className="font-medium m-0 mt-1">Manager</p>
          </div>
        </div>
      )}
      <div className="relative">
        <img
          src={AppIcons.Search}
          alt="Search Icon"
          className="absolute top-[18px] left-5"
        />
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          className="w-full pr-3 h-14 text-[14px] rounded-[57px] mb-3 border pl-14 focus:outline-none"
        />
      </div>
      <div className="flex flex-col">
        {messages.map((message, index) => (
          <MessageCard
            key={index}
            {...message}
            index={index}
            isActive={index?.toString() === location?.pathname?.split("/")[2]}
            onClick={() => handleCardClick(message.name)}
          />
        ))}
      </div>
    </div>
  );
};

export default MessageBox;
