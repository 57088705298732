// currentPageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const currentPageSlice = createSlice({
  name: "currentPage",
  initialState: "",
  reducers: {
    setCurrentPage: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCurrentPage } = currentPageSlice.actions;
export const selectCurrentPage = (state) => state.currentPage;
export default currentPageSlice.reducer;
