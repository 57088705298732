
  // loadGoogleMaps.js
export const loadGoogleMaps = (apiKey, libraries = []) => {
    return new Promise((resolve, reject) => {
      if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        resolve();
        return;
      }
  
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}&v=beta`;
      script.async = true;
      script.defer = true;
  
      script.onload = () => {
        resolve();
      };
  
      script.onerror = (error) => {
        reject(error);
      };
  
      document.head.appendChild(script);
    });
  };
  