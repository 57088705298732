import React, { useState, useEffect } from "react";
import { AppIcons } from "../assets";
import { fetchImage } from '../api/imageUpload.api'

const ProfileImage = ({ src, alt }) => (
  <img
    src={src}
    alt={alt}
    className="w-16 h-16 object-cover object-center rounded-full"
  />
);

const CompanyListCard = ({ listItem, editCompanyClick }) => {
  const { name, isActive, companyImageBG, doe, phone, email, users } = listItem;
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    fetchImage(companyImageBG)
      .then((img) => setImageUrl(img))

  }, [companyImageBG]);
  const totalStaf = users.length
  const formatDate = (utcDate) => {
    const date = new Date(utcDate);
    // Format the date as needed
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return formattedDate;
  };


  return (
    <div
      className={`py-4 px-4  border border-[#D8D8D8] rounded-3xl`}

    >
      <div className="flex gap-1 justify-between">
        <h2 className="text-lg font-bold max-md:max-w-full"></h2>
        <button className="flex items-center justify-center bg-white rounded-full w-10 h-10"
          onClick={() => editCompanyClick(listItem)}>
          <img loading="lazy" src={AppIcons.Edit} alt="" />
        </button>
      </div>
      <div className="flex gap-2">
        <ProfileImage
          src={imageUrl}
          alt="Ahsen Shamil"
        />
        <div className="flex flex-col gap-2">
          <h6 className="text-base font-bold">{name}</h6>
          <p className="text-gray-500">{phone.countryCode + phone.phoneNumber}</p>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex text-sm flex-row justify-between">
          <p className="text-gray-500">Email</p>
          <p>{email}</p>
        </div>
        <div className="flex text-sm flex-row justify-between">
          <p className="text-gray-500">Total Staff</p>
          <p>{totalStaf}</p>
        </div>
        <div className="flex flex-row text-sm justify-between">
          <p className="text-gray-500">Date of Expiry</p>
          <p>{formatDate(doe)}</p>
        </div>
        <div className="flex flex-row text-sm justify-between">
          <p className="text-gray-500">Active</p>
          <p
            className={`${isActive == true ? "text-green-500" : "text-red-500"
              }`}
          >
            {isActive == true ? "Active" : "Inactive"}
          </p>
        </div>
      </div>
      {/* <div className="flex mt-2 gap-4">
        <button
          onClick={completeWork}
          className=" py-2 px-4 bg-gradient-to-b from-[#01A0AA] to-[#076B9E] text-white font-medium rounded-[90px] h-[50px] w-[201px]"
        >
          Complete Work
        </button>
        <button onClick={cancelWork} className="ml-2 text-[#FC2C5A] font-bold">
          Cancel
        </button>
      </div> */}
    </div>
  );
};

export default CompanyListCard;